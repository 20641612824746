import * as firebase from "firebase/app";
import EditorViewModel from "../Editor/EditorViewModel";
import { stores, tables } from "../Config";
import { IReactionDisposer, observe } from "mobx";

class UserObserverViewModel {
  private readonly userRef: firebase.database.Reference;
  private dispose: IReactionDisposer | undefined;
  constructor(
    private readonly currentFirebaseApp: firebase.app.App,
    private readonly editorViewModel: EditorViewModel
  ) {
    const userId = currentFirebaseApp.auth().currentUser?.uid ?? "";
    this.userRef = this.currentFirebaseApp
      .database()
      .ref(`storeEditor/users/${userId}/connections`)
      .push();
    this.userRef.onDisconnect().remove().then();
  }

  async startObserving(): Promise<void> {
    observe(this.editorViewModel, async () => {
      await this.onStateChanged();
    });

    window.addEventListener("beforeunload", async () => {
      this.dispose?.();
      await this.userRef?.set(null);
    });
  }

  private async onStateChanged(): Promise<void> {
    const email = this.currentFirebaseApp.auth().currentUser?.email;
    if (email != null) {
      const store = stores.find(
        (store) =>
          store.value === this.editorViewModel.state.selectedStore?.storeKey
      );
      const table = tables.find(
        (table) => table.value === this.editorViewModel.state.selectedTable
      );
      if (store === undefined || table == undefined) {
        await this.userRef.set(null);
      } else {
        await this.userRef.set({
          userEmail: email,
          currentStore: store?.label,
          currentTable: table?.label,
        });
      }
    }
  }
}

export default UserObserverViewModel;
