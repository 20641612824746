import {
  AvailableModules,
  EditableModule,
  LinkConfigForm,
  SchedulingConfigForm,
} from "./Modules";
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import InputProcessor from "./InputProcessor";
import { StoreInfo } from "./StoreInfo";
import { AddModuleState, RefTypeKey, RefType } from "./AddModuleState";
import { formFieldWithRefsResolver } from "./FormFieldWithRefsResolver";
import { formFieldValuesParser } from "./FormFieldValuesParser";
import React from "react";
import { FormField } from "./FormItems";
import { HomepageModule } from "../TableViewer/HomepageViewer/types";
import formFieldWithRefsFactory from "./FormFieldWithRefsFactory";
import * as firebase from "firebase/app";
import EditorViewModel from "../Editor/EditorViewModel";
import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { FieldAvailabilityGroup, FieldGroup } from "./FieldGroup";

class AddModuleViewModel {
  state: AddModuleState = AddModuleState.default;
  private storeInfo: StoreInfo = {};
  private selectedStore?: string;
  private firebaseApp?: firebase.app.App;
  get supportedLanguages(): string[] {
    if (this.storeInfo.enabledLangauges === undefined) {
      return ["en"];
    } else {
      if (this.storeInfo.enabledLangauges.indexOf("en") === -1) {
        return ["en", ...this.storeInfo.enabledLangauges];
      } else {
        return this.storeInfo.enabledLangauges;
      }
    }
  }

  constructor(
    private readonly editorViewModel: EditorViewModel,
    private readonly inputProcessor: InputProcessor,
    private readonly onSaveListener: (module: Record<string, unknown>) => void
  ) {
    autorun(() => {
      if (
        this.selectedStore !==
        this.editorViewModel.state.selectedStore?.storeKey
      ) {
        this.selectedStore = this.editorViewModel.state.selectedStore?.storeKey;
        this.updateStateWithSelectedStore();
      }
    });

    makeObservable(this, {
      state: observable,
      addNewLinkConfig: action.bound,
      onModuleSelected: action.bound,
      onSaveClicked: action.bound,
      onLinkingEnabledToggled: action.bound,
      onTimeEnabledToggled: action.bound,
      onEnabledOptionalToggled: action.bound,
      addNewRef: action.bound,
      activeFieldRefs: computed,
      availableOptionalFields: computed,
    });
  }

  get activeFieldRefs(): {
    fields: FormFieldWithRefs[];
    linking: FormFieldWithRefs[];
    scheduling: FormFieldWithRefs[];
  } {
    return {
      fields: this.state.refs.fields.getActiveFields,
      linking: this.state.refs.linking.getActiveFields,
      scheduling: this.state.refs.scheduling.getActiveFields,
    };
  }

  get availableOptionalFields(): {
    fields: FormFieldWithRefs[];
    linking: FormFieldWithRefs[];
    scheduling: FormFieldWithRefs[];
  } {
    return {
      fields: this.state.refs.fields.availableOptionalFields,
      linking: this.state.refs.fields.availableOptionalFields,
      scheduling: this.state.refs.fields.availableOptionalFields,
    };
  }

  private combineRefs(): FormFieldWithRefs[] {
    return [
      ...this.state.refs.fields.getActiveFields,
      ...this.state.refs.linking.getActiveFields,
      ...this.state.refs.scheduling.getActiveFields,
    ];
  }

  private populateFieldGroups(
    selectedModule: EditableModule,
    currentModule?: HomepageModule
  ): RefType {
    const refs = [
      ...formFieldWithRefsFactory(
        selectedModule.fields.filter((it) => it.computed === undefined),
        currentModule ?? {},
        this.supportedLanguages
      ),
    ];
    const fieldGroups = this.createFieldGroups(refs, selectedModule);

    return {
      fields: this.state.refs.fields.groupRefs(
        fieldGroups.fields,
        currentModule
      ),
      linking: this.state.refs.linking.groupRefs(
        fieldGroups.linking,
        currentModule
      ),
      scheduling: this.state.refs.scheduling.groupRefs(
        fieldGroups.scheduling,
        currentModule
      ),
    };
  }

  private getFormFields(fieldType: RefTypeKey): FormField[] | undefined {
    switch (fieldType) {
      case "fields": {
        return this.state.selectedModule?.fields;
      }
      case "linking": {
        return this.state.selectedModule?.linkConfig;
      }
      case "scheduling": {
        return this.state.selectedModule?.scheduling;
      }
      default:
        break;
    }
  }

  private createFieldGroups(
    refs: FormFieldWithRefs[],
    selectedModule: EditableModule
  ): {
    fields: FormFieldWithRefs[];
    linking: FormFieldWithRefs[];
    scheduling: FormFieldWithRefs[];
  } {
    const fields = refs.filter(
      (item) =>
        selectedModule.fields.find(
          (fieldItem) => fieldItem === item.formItem
        ) !== undefined
    );

    const linking = refs.filter(
      (item) =>
        LinkConfigForm.find(
          (linkConfigItem) => linkConfigItem === item.formItem
        ) !== undefined
    );
    const scheduling = refs.filter(
      (item) =>
        SchedulingConfigForm.find(
          (schedulingConfigItem) => schedulingConfigItem === item.formItem
        ) !== undefined
    );
    return { fields, linking, scheduling };
  }

  private addToFieldRefs(
    field: FormField,
    refType: string,
    fieldAvailabilityGroup: FieldAvailabilityGroup,
    languageCode: string
  ): FormFieldWithRefs | undefined {
    const fieldRefs = this.state.refs[refType as RefTypeKey][
      fieldAvailabilityGroup
    ].find((item) => item.formItem.jsonKey === field.jsonKey);

    if (fieldRefs) {
      const refsForLanguage = fieldRefs.refs.find(
        (item) => item.languageCode === languageCode
      );
      if (refsForLanguage) {
        refsForLanguage.refs.push({
          ref: React.createRef<HTMLInputElement>(),
          initialValue: undefined,
        });
      } else {
        fieldRefs.refs.push({
          languageCode: languageCode,
          refs: [
            {
              ref: React.createRef<HTMLInputElement>(),
              initialValue: undefined,
            },
          ],
        });
      }
    }
    return fieldRefs;
  }

  updateStateWithSelectedStore(): void {
    const storeInfo = this.editorViewModel.state.selectedStore?.tables?.find(
      (item) => item.type === "Store Info"
    )?.document;
    const firebaseConfig = this.editorViewModel.state.selectedStore?.tables?.find(
      (item) => item.type === "Firebase"
    )?.document;
    const firebaseApp =
      firebase.apps.find(
        (item) => item.name === this.editorViewModel.state.selectedStore?.label
      ) ??
      firebase.initializeApp(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        firebaseConfig!,
        this.editorViewModel.state.selectedStore?.label
      );
    firebaseApp.auth().signInAnonymously().then();
    if (storeInfo) {
      this.storeInfo = storeInfo;
    }
    this.firebaseApp = firebaseApp;
  }

  onModuleSelected(
    moduleType: (availableModules: EditableModule[]) => EditableModule,
    currentModule?: HomepageModule
  ): void {
    const selectedModule = moduleType(AvailableModules);
    if (selectedModule) {
      // Creates refs, and groups them by FieldGroup type.
      const { fields, linking, scheduling } = this.populateFieldGroups(
        selectedModule,
        currentModule
      );

      this.state = this.state.copy({
        selectedModule,
        refs: {
          fields,
          linking,
          scheduling,
        },
        startTimeEnabled:
          [
            ...scheduling.mandatoryFields,
            ...scheduling.availableOptionalFields,
          ].filter((item) => item.formItem.jsonKey === "startTime").length ===
          1,
        endTimeEnabled:
          [
            ...scheduling.mandatoryFields,
            ...scheduling.availableOptionalFields,
          ].filter((item) => item.formItem.jsonKey === "endTime").length === 1,
        linkingEnabled:
          [...linking.mandatoryFields, ...linking.enabledOptionalFields].filter(
            (itemRef) =>
              LinkConfigForm.find(
                (item) => item.jsonKey === itemRef.formItem.jsonKey
              ) !== undefined
          ).length !== 0,
      });
    }
  }

  onLinkingEnabledToggled(): void {
    const updatedValue = !this.state.linkingEnabled;
    this.state = this.state.copy({
      linkingEnabled: updatedValue,
      refs: !updatedValue
        ? {
            ...this.state.refs,
            linking: FieldGroup.default,
          }
        : {
            ...this.state.refs,
            linking: this.state.refs.linking.copy({
              mandatoryFields: [
                ...formFieldWithRefsFactory(
                  LinkConfigForm.filter((formField) => formField.required),
                  {},
                  this.supportedLanguages
                ),
              ],
              enabledOptionalFields: [],
              availableOptionalFields: [
                ...formFieldWithRefsFactory(
                  LinkConfigForm.filter((formField) => !formField.required),
                  {},
                  this.supportedLanguages
                ),
              ],
            }),
          },
    });
  }

  // onEndTimeEnabledToggle and onStartTimeEnabledToggle fundamentally does the same thing. Removed one, introduced a
  // schedulingType variable to pass, to remove code repetition. Logic is the same, just filters for a different jsonKey.
  onTimeEnabledToggled(schedulingType: "endTime" | "startTime"): void {
    const updatedValue =
      schedulingType === "endTime"
        ? !this.state.endTimeEnabled
        : !this.state.startTimeEnabled;
    this.state = this.state.copy({
      endTimeEnabled:
        schedulingType === "endTime" ? updatedValue : this.state.endTimeEnabled,
      startTimeEnabled:
        schedulingType === "startTime"
          ? updatedValue
          : this.state.startTimeEnabled,
      refs: !updatedValue
        ? {
            ...this.state.refs,
            scheduling: this.state.refs.scheduling.copy({
              enabledOptionalFields: [
                ...this.state.refs.scheduling.enabledOptionalFields.filter(
                  (item) => item.formItem.jsonKey !== schedulingType
                ),
              ],
            }),
          }
        : {
            ...this.state.refs,
            scheduling: this.state.refs.scheduling.copy({
              mandatoryFields: [
                ...formFieldWithRefsFactory(
                  SchedulingConfigForm.filter((item) => item.required),
                  {},
                  this.supportedLanguages
                ),
              ],
              enabledOptionalFields: [
                ...this.state.refs.scheduling.enabledOptionalFields,
                ...formFieldWithRefsFactory(
                  SchedulingConfigForm.filter(
                    (item) => item.jsonKey === schedulingType
                  ),
                  {},
                  this.supportedLanguages
                ),
              ],
            }),
          },
    });
    // This solution assumes that end and start times are optional values.
  }

  onEnabledOptionalToggled(jsonKey: string, fieldType: RefTypeKey): void {
    // Creates a nested object for the field that needs to be toggled, or switches the boolean.
    const formFields = this.getFormFields(fieldType);

    if (formFields !== undefined) {
      this.state = this.state.copy({
        refs: {
          ...this.state.refs,
          [fieldType]: this.state.refs[fieldType].toggleOptionalField(
            jsonKey,
            formFields,
            this.supportedLanguages
          ),
        },
      });
    }
  }

  // Checks if there is any existing data passed from thge currently passed in json data.

  addNewLinkConfig(): void {
    this.state.refs.linking.getActiveFields.forEach((field) =>
      this.addNewRef(field.formItem, "en")
    );
  }

  addNewRef(field: FormField, languageCode: string): void {
    for (const refType in this.state.refs as RefType) {
      const mandatoryFieldRefs = this.addToFieldRefs(
        field,
        refType,
        "mandatoryFields",
        languageCode
      );
      const enabledOptionalFieldRefs = this.addToFieldRefs(
        field,
        refType,
        "enabledOptionalFields",
        languageCode
      );

      if (mandatoryFieldRefs || enabledOptionalFieldRefs) {
        const mandatoryFieldsWithAddedRefs = mandatoryFieldRefs
          ? this.state.refs[refType as RefTypeKey].mandatoryFields.replaceItem(
              mandatoryFieldRefs,
              (item) => item.formItem.jsonKey === field.jsonKey
            )
          : this.state.refs[refType as RefTypeKey].mandatoryFields;

        const enabledOptionalFieldsWithAddedRefs = enabledOptionalFieldRefs
          ? this.state.refs[
              refType as RefTypeKey
            ].enabledOptionalFields.replaceItem(
              enabledOptionalFieldRefs,
              (item) => item.formItem.jsonKey === field.jsonKey
            )
          : this.state.refs[refType as RefTypeKey].enabledOptionalFields;

        const newStateRefs: RefType = {
          ...this.state.refs,
          [refType]: this.state.refs[refType as RefTypeKey].copy({
            mandatoryFields: mandatoryFieldsWithAddedRefs,
            enabledOptionalFields: enabledOptionalFieldsWithAddedRefs,
          }),
        };

        this.state = this.state.copy({
          refs: newStateRefs,
        });
      }
    }
  }

  async onSaveClicked(): Promise<void> {
    try {
      if (this.state.selectedModule && this.firebaseApp) {
        runInAction(() => {
          this.state = this.state.copy({ submitting: true });
        });
        const combinedRefArray = this.combineRefs();
        // Get all form fields and process them
        const resolvedFormItems = await formFieldWithRefsResolver(
          this.state.selectedModule,
          this.supportedLanguages,
          combinedRefArray,
          this.inputProcessor
        );
        if (resolvedFormItems.errors.length > 0) {
          runInAction(() => {
            this.state = this.state.copy({
              submitting: false,
              errors: resolvedFormItems.errors.map((item) => {
                return {
                  formField: item.formField,
                  error: item.error.message,
                };
              }),
            });
          });
        } else {
          // Structure processed data into valid module JSON
          const jsonData = formFieldValuesParser(
            this.supportedLanguages,
            resolvedFormItems.success
          );
          // Save Module
          this.onSaveListener({
            moduleType: this.state.selectedModule.type,
            ...jsonData,
          });
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }
}

export default AddModuleViewModel;
