import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as firebase from "firebase";
import ServiceLoader from "./ServiceLoader";
import { createBrowserHistory } from "history";
import "./util/ArrayExtensions";

// Firebase setup
const uatConfig = {
  apiKey: "AIzaSyCo5D_lp1GG3VB8HC15Ps0kMfIONiXlceI",
  authDomain: "venn-uat.firebaseapp.com",
  databaseURL: "https://venn-uat.firebaseio.com",
  projectId: "venn-uat",
  storageBucket: "venn-uat.appspot.com",
  messagingSenderId: "833541363531",
  appId: "1:833541363531:web:cc4114f572dd1f7ed4face",
  measurementId: "G-Z4HZVFEGX8",
};
const prodConfig = {
  apiKey: "AIzaSyCu9z66SDYhgzu5guJkredtcQm3532cdNw",
  authDomain: "venn-prod-70222.firebaseapp.com",
  databaseURL: "https://venn-prod-70222.firebaseio.com",
  projectId: "venn-prod-70222",
  storageBucket: "venn-prod-70222.appspot.com",
  messagingSenderId: "549725479573",
  appId: "1:549725479573:web:8c3d1c372d93113aed319c",
  measurementId: "G-72Y0QTNCH6",
};

const mainApp = firebase.initializeApp(
  process.env.REACT_APP_STAGE === "uat" ? uatConfig : prodConfig
);
const prodApp = firebase.initializeApp(prodConfig, "prod-app");
const isProduction = process.env.REACT_APP_STAGE !== "uat";

const setup = async () => {
  await mainApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  await prodApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
};
const history = createBrowserHistory();
const serviceLoader = new ServiceLoader(
  isProduction ? [mainApp] : [mainApp, prodApp],
  history
);
export const ServiceLoaderContext = React.createContext(serviceLoader);
serviceLoader.appViewModel.checkUser().then();

setup().then(() => {
  ReactDOM.render(
    <ServiceLoaderContext.Provider value={serviceLoader}>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <App
            history={history}
            viewModel={serviceLoader.appViewModel}
            prodFirebaseApp={prodApp}
            currentFirebaseApp={mainApp}
            isProduction={isProduction}
          />
        )}
      </ServiceLoaderContext.Consumer>
    </ServiceLoaderContext.Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
