import { SelectorOption } from "./components/Selector";

export const stores: SelectorOption[] = [
  { value: "c70f0c40-78b4-11ea-9167-f7c4afbaf99b", label: "Venn UAT" },
  { value: "6c54f2a0-09f7-11ea-b856-f7fdc57116d5", label: "Lazy Oaf" },
  { value: "e18601f0-9665-11e9-a60b-ff885a215eed", label: "Maison BMore" },
  { value: "f29f7620-9669-11e9-a60b-ff885a215eed", label: "Oud Milano UK" },
  {
    value: "a3c3dff0-0978-11ea-9128-7b1b043385c0",
    label: "Oud Milano UAE",
  },
  {
    value: "0bc5f560-07d6-11ea-b3f0-31475a223525",
    label: "Oud Milano SA",
  },
  {
    value: "0d43c760-0979-11ea-9128-7b1b043385c0",
    label: "Oud Milano KW",
  },
  {
    value: "9ce85350-d17d-11ea-9de7-edfe7829cf04",
    label: "Oud Milano OM",
  },
  { value: "39ee04c0-15b7-11ea-97e8-cb0e91a1c4b4", label: "Apartment51" },
  {
    value: "3d693c50-98de-11ea-a2af-612e51a09eea",
    label: "The Pulse Boutique",
  },
  { value: "259b7a00-b532-11ea-82fb-43642c84b00c", label: "Club L UK" },
  { value: "c2d78d40-b532-11ea-82fb-43642c84b00c", label: "Club L US" },
  { value: "c6ad8400-d72a-11ea-bb65-ebb1deef595b", label: "Route One" },
  {
    value: "c863e6b0-2b15-11eb-abd4-7db9a4ae81af",
    label: "Route One (Raffle)",
  },
  { value: "e000b610-d72b-11ea-832e-8d51ef0c17fd", label: "Motel Rocks UK" },
  { value: "c8ccb320-d72d-11ea-b49a-cb5debb0e23d", label: "Motel Rocks US" },
  { value: "6a55a7c0-d72d-11ea-bcfc-d9aa8cee5cdb", label: "Motel Rocks EU" },
  { value: "49ae6440-d72c-11ea-832e-8d51ef0c17fd", label: "Motel Rocks AU" },
  {
    value: "d505b2c0-ef98-11eb-8c28-d7b65d5182f9",
    label: "Motel Rocks (Redesign)",
  },
  { value: "773dab90-fcb2-11ea-8e57-fb88b3b2b918", label: "Raeburn" },
  {
    value: "55f46090-22a1-11eb-9b7f-571747abb2f6",
    label: "Rebellious Fashion (UK)",
  },
  {
    value: "dba54480-a80b-11eb-ad12-7524831f9997",
    label: "Rebellious Fashion (EU)",
  },
  {
    value: "a006ab20-a825-11eb-a70f-e7468db1cdc2",
    label: "Rebellious Fashion (US)",
  },
  {
    value: "1fa31ee0-a8d0-11eb-9e78-c506c4264858",
    label: "Rebellious Fashion (AU)",
  },
  {
    value: "045b8d70-62df-11eb-a177-577709eb52a5",
    label: "MADA IN CHINA",
  },
  { value: "e8afab30-74ed-11eb-b632-59ec02368c5d", label: "Maison7" },
  { value: "e48360f0-8272-11eb-894c-852e0d99f91b", label: "Public Desire UK" },
  { value: "92139960-8273-11eb-89f2-1fa90555d785", label: "Public Desire EU" },
  { value: "33881ba0-8273-11eb-83ea-9fbd11633612", label: "Public Desire US" },
  {
    value: "0251ef30-b6fc-11eb-be0a-6b484313146a",
    label: "Lords and Labradors",
  },
  { value: "916c9cf0-b716-11eb-8f8e-fd786e0de3c3", label: "Peyango" },
  { value: "ae771930-ef13-11eb-a16d-cb7c4eaccd7a", label: "amongst few" },
  {
    value: "72d875c0-f541-11eb-89ce-efb0dc6a1676",
    label: "amongst few (Drops)",
  },
  {
    value: "2bd2ed00-ef14-11eb-b663-73c0d2dad99c",
    label: "Bee Inspired Clothing",
  },
  {
    value: "afc39430-2c0d-11ec-9e4d-133555eba25c",
    label: "Jum3a",
  },
  {
    value: "804e3de0-2d8e-11ec-a872-3d2e86c83fba",
    label: "Never Fully Dressed",
  },
  {
    value: "f8c18490-6d61-11ec-987b-dbe740d9c3bd",
    label: "Watergate Street Gallery",
  },
  {
    value: "8f33a730-84ca-11ec-a204-317318213a8a",
    label: "Toteme (International)",
  },
  {
    value: "fe9cc570-84ca-11ec-a204-317318213a8a",
    label: "Toteme (SE)",
  },
  {
    value: "4712e190-84cb-11ec-a204-317318213a8a",
    label: "Toteme (US)",
  },
  {
    value: "eed7b8c0-92df-11ec-88a6-87d0821418bd",
    label: "Suffire - Dashboard",
  },
  {
    value: "aee3d2d0-a630-11ec-b53f-49a510f3be08",
    label: "Soundporium",
  },
  {
    value: "bfc5b310-b036-11ec-97fe-9122801c5c13",
    label: "Sugar Dumpling Kids",
  },
  {
    value: "9c09aa10-a9d5-11ec-90d9-45a63eb56062",
    label: "By Symphony",
  },
  {
    value: "bbcb4c20-45a0-11ed-9328-19f551212175",
    label: "Super 7 Mart",
  },
  {
    value: "47aa7c70-b96a-11ec-94bb-fd5f0b63ab96",
    label: "iOS Test Shop",
  },
  {
    value: "8b95ce60-bbdd-11ec-ac5b-b98dc5b7e7c4",
    label: "Bliss",
  },
  {
    value: "78623050-cacd-11ec-b3fb-056cfc0ef9a8",
    label: "Pink Boutique",
  },
  {
    value: "9aeb1540-f635-11ec-91c5-e327598e8188",
    label: "Fashion.sa",
  },
  {
    value: "65f6c2d0-f78e-11ec-9ff1-430fab30c70b",
    label: "Juvia",
  },
  {
    value: "d3f9e3f0-02bc-11ed-bfee-254a4c22187e",
    label: "Uskees",
  },
  {
    value: "2fc5bb20-0810-11ed-b155-737c0d843acc",
    label: "Port Group",
  },
  {
    value: "9dbba300-0910-11ed-92f1-5b7ccc8443fc",
    label: "Boutique Store",
  },
  {
    value: "26c9dc00-1b6a-11ed-ae8b-df71406835a7",
    label: "Lashtrix",
  },
];

export type TableType =
  | "Theme"
  | "Homepage"
  | "Product Page"
  | "Store Info"
  | "Settings"
  | "Firebase"
  | "Payment Providers"
  | "iOS App Config"
  | "Android App Config"
  | "Dashboard Settings - Engineering Only"
  | "Dashboard Menus - Engineering Only"
  | "Filter Translations";

export const tables: { value: string; label: TableType }[] = [
  { value: "themes", label: "Theme" },
  { value: "homepages", label: "Homepage" },
  { value: "productPages", label: "Product Page" },
  { value: "stores", label: "Store Info" },
  { value: "settings", label: "Settings" },
  { value: "firebase", label: "Firebase" },
  { value: "paymentProviders", label: "Payment Providers" },
  { value: "iosAppConfig", label: "iOS App Config" },
  { value: "androidAppConfig", label: "Android App Config" },
  {
    value: "dashboardSettings",
    label: "Dashboard Settings - Engineering Only",
  },
  {
    value: "menus",
    label: "Dashboard Menus - Engineering Only",
  },
  { value: "filterTranslations", label: "Filter Translations" },
];
