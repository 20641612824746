import React, { useRef } from "react";
import styles from "../../styles/SearchSideView.module.css";
import Button from "../../components/Button";
import SearchSideViewViewModel from "./SearchSideViewViewModel";
import { useObserver } from "mobx-react";

interface SearchSideViewProps {
  readonly title: string;
  readonly viewModel: SearchSideViewViewModel;
}

const SearchSideView: React.FunctionComponent<SearchSideViewProps> = ({
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return useObserver(() => (
    <div className={styles.searchSideView}>
      <input
        className={styles.input}
        placeholder={`${props.title} Handle`}
        type="text"
        ref={inputRef}
      />
      {props.viewModel.state.result !== "" &&
        props.viewModel.state.result !== null && (
          <p>{props.viewModel.state.result}</p>
        )}
      <Button
        label={`Search ${props.title}`}
        onClick={async () =>
          await props.viewModel.onSubmitClicked(inputRef.current?.value ?? "")
        }
      />
    </div>
  ));
};

export default SearchSideView;
