import React, { useCallback } from "react";
import styles from "../styles/HomepageViewer.module.css";
import { HomepageModuleCard } from "./HomepageModuleCard";
import update from "immutability-helper";
import HomepageViewerViewModel, {
  ModuleRepresentation,
} from "./HomepageViewerViewModel";
import { Observer } from "mobx-react";
import Button from "../../components/Button";
import AddModule from "../../AddModule";
import { HomepageModule } from "./types";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

interface HomepageViewerProps {
  readonly viewModel: HomepageViewerViewModel;
}
const HomepageViewer: React.FunctionComponent<HomepageViewerProps> = ({
  ...props
}) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = props.viewModel.state.modules[dragIndex];
      props.viewModel.onCurrentModuleOrderChanged(
        update(props.viewModel.state.modules, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [props.viewModel.state.modules]
  );

  const renderCard = (card: ModuleRepresentation, index: number) => {
    const originalIndex = props.viewModel.state.originalModules.findIndex(
      (item) => item.id === card.id
    );
    const originalItem = props.viewModel.state.originalModules[originalIndex];
    const history = useHistory();
    const { url } = useRouteMatch();
    return (
      <HomepageModuleCard
        key={card.id}
        onEditClicked={() => {
          history.push(`${url}/${index}/edit`);
        }}
        onDeleteClicked={() => {
          props.viewModel.onCurrentModuleOrderChanged(
            props.viewModel.state.modules.filter((item) => item.id !== card.id)
          );
        }}
        originalRepresentation={{
          data: originalItem,
          index: originalIndex,
        }}
        currentRepresentation={{
          data: card,
          index,
        }}
        moveCard={moveCard}
      />
    );
  };
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${url}/:moduleIndex/edit`}
        render={(routeProps) => {
          // Passing a string from params. Added parseInt to convert that to the expected number.
          const index = parseInt(routeProps.match.params.moduleIndex);
          return (
            <AddModule
              onSaveListener={(module: Record<string, unknown>) =>
                props.viewModel.onEditDone(module as HomepageModule, index)
              }
              homepageModule={props.viewModel.state.modules[index].data}
            />
          );
        }}
      />
      <Route path="*">
        <Observer
          render={() => (
            <div>
              <div className={styles.buttonsContainer}>
                <Button
                  label="Remove expired"
                  onClick={() => props.viewModel.onRemoveExpiredModules()}
                />
              </div>
              <div className={styles.tabContainer}>
                {props.viewModel.state.tabs.map((key) => {
                  return (
                    <button
                      key={key}
                      className={`${styles.tab} ${
                        props.viewModel.state.selectedTab === key
                          ? styles.active
                          : ""
                      }`}
                      onClick={() => {
                        props.viewModel.onSelectedDocumentKeyChanged(key);
                      }}
                    >
                      {key}
                    </button>
                  );
                })}
              </div>
              <div>
                <div>
                  {props.viewModel.state.modules.map((module, index) => {
                    return renderCard(module, index);
                  })}
                </div>
              </div>
            </div>
          )}
        />
      </Route>
    </Switch>
  );
};

export default HomepageViewer;
