import EditorViewModel from "../../Editor/EditorViewModel";
import Button from "../../components/Button";
import React from "react";
import styles from "../SyncSide/SyncSideView.module.css";
import searchStyles from "../../styles/SearchSideView.module.css";
import { useState } from "react";

interface Props {
  editorViewModel: EditorViewModel;
}

const AddStorekeySideView: React.FC<Props> = ({ editorViewModel }) => {
  const [storeName, setStoreName] = useState("");
  const [storeKey, setStoreKey] = useState("");

  const onSubmit = async () => {
    if (storeName === "" ?? !storeName) {
      alert("Store name is required");
      return;
    }

    if (storeKey.length !== 36) {
      alert(
        "Storekey needs to be 36 character long. Are you sure this is correct?"
      );
      return;
    }

    const newStore = {
      label: storeName,
      value: storeKey,
    };

    const result = await editorViewModel.onAddRemoteStorekey(newStore);

    if (!result.success && result.error) {
      alert(`Error: ${result.error}`);
    }

    alert("Storekey successfully saved");

    setStoreName("");
    setStoreKey("");
  };

  return (
    <div className={styles.SyncSideView}>
      <label htmlFor="storeName">Store Name</label>
      <input
        className={searchStyles.input}
        type="text"
        style={{ marginBottom: "12px" }}
        value={storeName}
        onChange={(e) => setStoreName(e.target.value)}
      />
      <label htmlFor="storeKey">Storekey</label>
      <input
        className={searchStyles.input}
        style={{ marginBottom: "24px" }}
        type="text"
        value={storeKey}
        onChange={(e) => setStoreKey(e.target.value)}
      />
      <Button onClick={onSubmit} label={"Add Storekey"} />
    </div>
  );
};

export default AddStorekeySideView;
