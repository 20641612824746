import React from "react";
import { Alert, Col, Form, Modal } from "react-bootstrap";
import { ServiceLoaderContext } from "../index";
import AddModuleViewModel from "./AddModuleViewModel";
import { useObserver } from "mobx-react";
import Button from "../components/Button";
import Selector from "../components/Selector";
import FormRenderer from "./FormRenderer";
import { AvailableModules, ModuleType } from "./Modules";
import style from "./styles/AddModule.module.css";
import { HomepageModule } from "../TableViewer/HomepageViewer/types";
import ModulePreview from "../ModulePreview";
import { useHistory } from "react-router-dom";
import { RefTypeKey } from "./AddModuleState";

interface AddModuleProps {
  homepageModule?: HomepageModule;
  onSaveListener?: (module: Record<string, unknown>) => void;
}

const _AddModule: React.FunctionComponent<
  AddModuleProps & {
    viewModel: AddModuleViewModel;
  }
> = ({ ...props }) => {
  const history = useHistory();

  const getTitle = (refType: RefTypeKey): string | undefined => {
    if (refType === "fields") {
      return "Attributes";
    } else if (refType === "linking") {
      return "Links";
    } else if (refType === "scheduling") {
      return "Scheduling";
    }
  };

  return useObserver(() => (
    <div
      style={{
        flexGrow: 1,
        minWidth: "75%",
        padding: "20px",
      }}
    >
      <Button label="Back" onClick={() => history.goBack()} />

      <Modal.Header>
        <Col>
          <Modal.Title>{`${
            props.homepageModule
              ? `Edit module ${props.homepageModule.moduleType}`
              : "Create new module"
          }`}</Modal.Title>
          {props.homepageModule && (
            <ModulePreview
              moduleType={props.homepageModule.moduleType as ModuleType}
              data={props.homepageModule}
            />
          )}
        </Col>
      </Modal.Header>
      <Modal.Body>
        {props.homepageModule === undefined && (
          <Selector
            isDisabled={props.viewModel.state.selectedModule !== undefined}
            currentValue={props.viewModel.state.selectedModule?.type ?? ""}
            placeholder="Module Type"
            options={AvailableModules.map((item) => {
              return {
                label: item.readableModuleName,
                value: item.readableModuleName,
              };
            })}
            onValueChanged={(value) => {
              props.viewModel.onModuleSelected(
                (allmodules) =>
                  allmodules.find((item) => item.readableModuleName === value)! // eslint-disable-line
              );
            }}
          />
        )}
        {props.viewModel.state.selectedModule && (
          <div className={style.formRendererContainer}>
            {props.viewModel.state.errors.length > 0 && (
              <Alert variant="danger">
                <span>Error processing form</span>
                <ul>
                  {props.viewModel.state.errors.map((item) => {
                    return (
                      <li
                        key={`error${item.formField.jsonKey}`}
                      >{`${item.formField.label}: ${item.error}`}</li>
                    );
                  })}
                </ul>
              </Alert>
            )}
            <Form.Check
              disabled={props.viewModel.state.submitting}
              inline
              type="checkbox"
              label="Add start time"
              checked={props.viewModel.state.startTimeEnabled}
              onChange={() => props.viewModel.onTimeEnabledToggled("startTime")}
            />
            <Form.Check
              disabled={props.viewModel.state.submitting}
              inline
              type="checkbox"
              label="Add end time"
              checked={props.viewModel.state.endTimeEnabled}
              onChange={() => props.viewModel.onTimeEnabledToggled("endTime")}
            />
            <Form.Check
              disabled={props.viewModel.state.submitting}
              inline
              type="checkbox"
              label="Enable Linking"
              checked={props.viewModel.state.linkingEnabled}
              onChange={props.viewModel.onLinkingEnabledToggled}
            />
            {Object.keys(props.viewModel.activeFieldRefs).map(
              (refType, index) => (
                <div key={`${refType}${index}`}>
                  {props.viewModel.activeFieldRefs[refType as RefTypeKey]
                    .length > 0 && <hr />}
                  {props.viewModel.activeFieldRefs[refType as RefTypeKey]
                    .length > 0 && (
                    <h1 style={{ paddingTop: "20px" }}>
                      {getTitle(refType as RefTypeKey)}
                    </h1>
                  )}
                  {refType === "linking" &&
                    props.viewModel.activeFieldRefs[refType as RefTypeKey]
                      .length > 0 && (
                      <div>
                        {/* Build functionality for getting disabled components*/}
                        {props.viewModel.state.refs[
                          refType
                        ].availableOptionalFields.map((item) => (
                          <Form.Check
                            key={`${item.formItem.jsonKey}CheckBox`}
                            disabled={props.viewModel.state.submitting}
                            inline
                            type="checkbox"
                            label={item.formItem.label}
                            checked={props.viewModel.state.refs[
                              refType as RefTypeKey
                            ].isEnabled(item.formItem.jsonKey)}
                            onChange={() =>
                              props.viewModel.onEnabledOptionalToggled(
                                item.formItem.jsonKey,
                                "linking"
                              )
                            }
                          />
                        ))}
                      </div>
                    )}
                  <FormRenderer
                    isSubmitting={props.viewModel.state.submitting}
                    errors={props.viewModel.state.errors}
                    formFieldRefs={
                      props.viewModel.activeFieldRefs[refType as RefTypeKey]
                    }
                    onAddRef={props.viewModel.addNewRef}
                    languages={props.viewModel.supportedLanguages}
                  />
                  {props.viewModel.state.linkingEnabled &&
                    refType === "linking" && (
                      <Button
                        onClick={props.viewModel.addNewLinkConfig}
                        label="Add More"
                      />
                    )}
                </div>
              )
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          isDisabled={props.viewModel.state.submitting}
          label="Save"
          onClick={props.viewModel.onSaveClicked}
        />
      </Modal.Footer>
    </div>
  ));
};

const AddModule: React.FunctionComponent<AddModuleProps> = ({ ...props }) => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => (
        <_AddModule
          viewModel={serviceLoader.addModuleViewModel(
            props.homepageModule,
            props.onSaveListener
          )}
          {...props}
        />
      )}
    </ServiceLoaderContext.Consumer>
  );
};

export default AddModule;
