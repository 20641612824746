import { HomepageModule } from "../TableViewer/HomepageViewer/types";
import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { FormField } from "./FormItems";
import React from "react";
import { LinkConfigForm, SchedulingConfigForm } from "./Modules";
import formFieldWithRefsFactory from "./FormFieldWithRefsFactory";

export type FieldAvailabilityGroup =
  | "mandatoryFields"
  | "enabledOptionalFields"
  | "availableOptionalFields";

export class FieldGroup {
  static default = new FieldGroup([], [], []);

  constructor(
    readonly mandatoryFields: FormFieldWithRefs[],
    readonly enabledOptionalFields: FormFieldWithRefs[],
    readonly availableOptionalFields: FormFieldWithRefs[]
  ) {}

  get getActiveFields(): FormFieldWithRefs[] {
    return [...this.mandatoryFields, ...this.enabledOptionalFields];
  }

  static getGroupedFieldName(formField: FormField): FieldAvailabilityGroup {
    return formField.required ? "mandatoryFields" : "enabledOptionalFields";
  }

  // Checks if the optional field has any incoming default value associated with it
  // by searching the json.
  static isExistingOptionalField(
    field: FormField,
    currentModule: HomepageModule
  ): boolean {
    if (field.required) {
      return false;
    }
    const linkField: boolean =
      LinkConfigForm.find((item) => item.jsonKey === field.jsonKey) !==
      undefined;

    const multipleLinkField: boolean =
      LinkConfigForm.find((item) => item.jsonKey === field.jsonKey) !==
        undefined && currentModule.attributes.links !== undefined;

    const schedulingField: boolean =
      SchedulingConfigForm.find((item) => item.jsonKey === field.jsonKey) !==
      undefined;

    const existingField: boolean =
      field.isAttribute && !linkField && !schedulingField && !multipleLinkField
        ? currentModule.attributes?.[field.jsonKey] !== undefined
        : linkField && !multipleLinkField
        ? currentModule.attributes?.link?.[field.jsonKey] !== undefined
        : multipleLinkField
        ? currentModule.attributes.links?.[0][field.jsonKey] !== undefined
        : schedulingField
        ? currentModule[field.jsonKey] !== undefined
        : false;
    const result = existingField;
    return result;
  }

  static findUniqueRefs(formFields: FormFieldWithRefs[]): FormFieldWithRefs[] {
    const uniqueRefs: FormFieldWithRefs[] = [];
    const existingRefs: Record<string, boolean> = {};
    formFields.forEach((formField) => {
      if (!existingRefs[formField.formItem.jsonKey]) {
        uniqueRefs.push(formField);
        existingRefs[formField.formItem.jsonKey] = true;
      }
    });
    return uniqueRefs;
  }

  groupRefs(
    fields: FormFieldWithRefs[],
    currentModule?: HomepageModule
  ): FieldGroup {
    return currentModule
      ? this.copy({
          mandatoryFields: fields.filter((field) => field.formItem.required),
          enabledOptionalFields: fields.filter(
            (field) =>
              !field.formItem.required &&
              FieldGroup.isExistingOptionalField(field.formItem, currentModule)
          ),
          // Need to push only once!
          availableOptionalFields: FieldGroup.findUniqueRefs(
            fields.filter((field) => !field.formItem.required)
          ),
        })
      : this.copy({
          mandatoryFields: fields.filter((field) => field.formItem.required),
          enabledOptionalFields: fields.filter(
            (field) => !field.formItem.required
          ),
          availableOptionalFields: fields.filter(
            (field) => !field.formItem.required
          ),
        });
  }

  isEnabled(jsonKey: string): boolean {
    return !!this.enabledOptionalFields.find(
      (field) => field.formItem.jsonKey === jsonKey
    );
  }

  toggleOptionalField(
    jsonKey: string,
    formFields: FormField[],
    supportedLanguages: string[]
  ): FieldGroup {
    const currentAmountPerLanguage = supportedLanguages.map((language) => {
      return this.getActiveFields
        .map((field) => {
          const languageRefs = field.refs.find(
            (ref) => ref.languageCode === language
          );
          return languageRefs?.refs?.length ?? 0;
        })
        .reduce((prev, current) => (prev > current ? prev : current), 0);
    });
    const updatedValue = !this.isEnabled(jsonKey);
    if (!updatedValue) {
      return this.copy({
        enabledOptionalFields: this.enabledOptionalFields.filter(
          (ref) => jsonKey !== ref.formItem.jsonKey
        ),
      });
    } else {
      const createdFormFieldWithRefs: FormFieldWithRefs[] = formFieldWithRefsFactory(
        formFields.filter((fieldItem) => jsonKey === fieldItem.jsonKey),
        {},
        supportedLanguages
      ).map<FormFieldWithRefs>((initialFormFieldWithRef) => {
        return {
          ...initialFormFieldWithRef,
          refs: initialFormFieldWithRef.refs.map((existingRef) => {
            const languageIndex = supportedLanguages.indexOf(
              existingRef.languageCode
            );
            const languageAmount = currentAmountPerLanguage[languageIndex];
            if (languageAmount == 0) {
              return {
                ...existingRef,
                languageCode: existingRef.languageCode,
                refs: [],
              };
            }
            if (languageAmount > existingRef.refs.length) {
              const difference = languageAmount - existingRef.refs.length;
              const refs = existingRef.refs;
              for (let index = 0; index < difference; index++) {
                refs.push({
                  ref: React.createRef<HTMLInputElement>(),
                  initialValue: undefined,
                });
              }
              return {
                ...existingRef,
                languageCode: existingRef.languageCode,
                refs: refs,
              };
            }
            if (languageAmount < existingRef.refs.length) {
              const difference = existingRef.refs.length - languageAmount;
              existingRef.refs.splice(0, difference);
              return {
                ...existingRef,
                languageCode: existingRef.languageCode,
                refs: existingRef.refs,
              };
            }
            return existingRef;
          }),
        };
      });
      return this.copy({
        enabledOptionalFields: [
          ...this.enabledOptionalFields,
          ...createdFormFieldWithRefs,
        ],
      });
    }
  }

  copy(overrides: Partial<FieldGroup>): FieldGroup {
    return new FieldGroup(
      overrides.mandatoryFields ?? this.mandatoryFields,
      overrides.enabledOptionalFields ?? this.enabledOptionalFields,
      overrides.availableOptionalFields ?? this.availableOptionalFields
    );
  }
}
