import React from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import FormControlTime from "./FormControlTime";
import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { useObserver } from "mobx-react";
import FormControlSelect from "./FormControlSelect";

const FormItemRenderer: React.FunctionComponent<{
  readonly formField: FormFieldWithRefs;
  readonly isSubmitting: boolean;
  readonly languages: string[];
  readonly onAddRef: (languageCode: string) => void;
  readonly error?: string;
}> = ({ ...props }) => {
  return useObserver(() => (
    <Form.Group>
      <Form.Label>{props.formField.formItem.label}</Form.Label>
      {props.formField.refs.map((itemRef, index) => (
        <React.Fragment key={index}>
          {itemRef.refs.map((ref, index) => {
            return (
              <InputGroup key={`${itemRef.languageCode}${index}`}>
                <InputGroup.Prepend>
                  <InputGroup.Text>{itemRef.languageCode}</InputGroup.Text>
                </InputGroup.Prepend>
                {props.formField.formItem.type === "file" ? (
                  <Form.File
                    accept="image/*,.mp4"
                    isValid={props.error === undefined}
                    disabled={props.isSubmitting}
                    key={`${itemRef.languageCode}${index}${props.formField.formItem.type}`}
                    ref={ref.ref}
                  />
                ) : props.formField.formItem.type === "datetime-local" ? (
                  <FormControlTime
                    initialValue={ref.initialValue as number | undefined}
                    error={props.error}
                    disabled={props.isSubmitting}
                    key={`${itemRef.languageCode}${index}`}
                    ref={ref.ref}
                  />
                ) : props.formField.formItem.type === "select" ? (
                  <FormControlSelect
                    initialValue={ref.initialValue as string | undefined}
                    error={props.error}
                    disabled={props.isSubmitting}
                    key={`${itemRef.languageCode}${index}`}
                    ref={ref.ref}
                    placeholder={props.formField.formItem.label}
                    options={props.formField.formItem.options ?? []}
                  />
                ) : (
                  <FormControl
                    key={`${itemRef.languageCode}${index}`}
                    ref={ref.ref}
                    defaultValue={
                      ref.initialValue !== undefined
                        ? (ref.initialValue as string)
                        : undefined
                    }
                    isValid={props.error === undefined}
                    disabled={props.isSubmitting}
                    type={props.formField.formItem.type}
                  />
                )}
              </InputGroup>
            );
          })}
          {props.error && (
            <FormControl.Feedback type="invalid">
              {props.error}
            </FormControl.Feedback>
          )}
          {props.formField.formItem.multipleInput && (
            <a
              key={`${itemRef.languageCode}${index}more`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (!props.isSubmitting) {
                  props.onAddRef(itemRef.languageCode);
                }
              }}
            >
              Add more
            </a>
          )}
        </React.Fragment>
      ))}
      <br />
      {props.languages
        .filter(
          (languageCode) =>
            props.formField.refs.find(
              (ref) => ref.languageCode === languageCode
            ) === undefined
        )
        .map((languageCode, index) => (
          <a
            key={`${languageCode}${index}new`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (!props.isSubmitting) {
                props.onAddRef(languageCode);
              }
            }}
          >
            Configure {languageCode}
          </a>
        ))}
      <Form.Text className="text-muted">
        {props.formField.formItem.description}
      </Form.Text>
    </Form.Group>
  ));
};

export default FormItemRenderer;
