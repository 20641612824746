import { SelectorOption } from "../../components/Selector";

export const syncOptions: SelectorOption[] = [
  { label: "Entire Sync", value: "/sync" },
  { label: "Menu", value: "/sync/menu" },
  { label: "Products", value: "/sync/products" },
  { label: "Collections", value: "/sync/collections" },
  { label: "Metafields", value: "/sync/metafields" },
  { label: "Customers", value: "/sync/customers" },
  { label: "Blogs", value: "/sync/blogs" },
  { label: "Inventory", value: "/sync/inventory" },
  { label: "Collection Translations", value: "/sync/translations/collections" },
  { label: "Product Translations", value: "/sync/translations/products" },
  { label: "Currency", value: "/sync/currency" },
  { label: "Make Sales Channel Available", value: "/sync/make-data-available" },
  { label: "Price Lists", value: "/sync/priceLists" },
  { label: "International Pricing", value: "/sync/international-pricing" },
];
