import React, { useEffect } from "react";
import styles from "./styles/StoreSelector.module.css";
import Selector from "../components/Selector";
import { tables } from "../Config";
import { useHistory, useParams } from "react-router-dom";
import { ServiceLoaderContext } from "../index";
import EditorViewModel from "../Editor/EditorViewModel";
import { useObserver } from "mobx-react";

interface RouteParams {
  readonly store?: string;
  readonly table?: string;
}

const _StoreSelector: React.FC<{ editorViewModel: EditorViewModel }> = ({
  ...props
}) => {
  const stores = props.editorViewModel.state.storekeys;
  const history = useHistory();
  const params = useParams<RouteParams>();
  const currentStore = stores.find((item) => item.value === params.store);
  const currentTable = tables.find((item) => item.value === params.table);

  useEffect(() => {
    if (currentStore && currentTable) {
      props.editorViewModel.onSelectedStoreAndTableUpdated(
        currentStore.value,
        currentTable.value
      );
    }
  }, [currentStore]);
  useEffect(() => {
    if (currentStore && currentTable) {
      props.editorViewModel.onSelectedTableUpdated(currentTable.value);
    }
  }, [currentTable]);

  return useObserver(() => (
    <div className={styles.selectorContainer}>
      <Selector
        currentValue={currentStore?.value ?? ""}
        options={props.editorViewModel.state.storekeys}
        placeholder="Stores"
        onValueChanged={(newStore) => {
          const store = stores.find((item) => item.value === newStore);
          if (store) {
            history.push(`/${store.value}/${params.table ?? ""}`);
          }
        }}
      />
      <Selector
        isDisabled={!currentStore}
        placeholder="Components"
        currentValue={currentTable?.value ?? ""}
        options={tables}
        onValueChanged={(newTable) => {
          const table = tables.find((item) => item.value === newTable);
          if (table) {
            history.push(`/${currentStore?.value}/${table.value}`);
          }
        }}
      />
    </div>
  ));
};

const StoreSelector: React.FC = () => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => {
        return (
          <_StoreSelector editorViewModel={serviceLoader.editorViewModel} />
        );
      }}
    </ServiceLoaderContext.Consumer>
  );
};

export default StoreSelector;
