import React, { useRef } from "react";
import styles from "../../styles/SearchSideView.module.css";
import Button from "../../components/Button";
import FileUploaderViewModel from "./FileUploaderViewModel";
import { Form } from "react-bootstrap";
import { observer } from "mobx-react";
import { Clipboard } from "react-feather";
import uploaderStyles from "./styles/Fileuploader.module.css";

interface Props {
  viewModel: FileUploaderViewModel;
}

const FileUploader: React.FC<Props> = ({ viewModel }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={styles.searchSideView}>
      <Form.File
        isValid={viewModel.state.hasError}
        disabled={viewModel.state.submitting}
        ref={inputRef}
      />

      {viewModel.state.result !== "" && (
        <div className={uploaderStyles.resultContainer}>
          <div className={uploaderStyles.resultText}>
            {viewModel.state.result}
          </div>
          <button
            className={uploaderStyles.clipboardButton}
            onClick={() =>
              navigator.clipboard.writeText(viewModel.state.result)
            }
          >
            <Clipboard />
          </button>
        </div>
      )}

      {viewModel.state.hasError && <p>Failed to upload</p>}
      <Button
        isDisabled={viewModel.state.submitting}
        label={`Upload file`}
        onClick={async () => {
          if (inputRef.current != null) {
            await viewModel.onSubmitClicked(inputRef.current);
          }
        }}
      />
    </div>
  );
};

export default observer(FileUploader);
