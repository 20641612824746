import EditorViewModel from "../../Editor/EditorViewModel";
import { action, makeObservable, observable, reaction } from "mobx";
import { HomepageDocument, HomepageModule } from "./types";
import * as lodash from "lodash";
import * as moment from "moment-timezone";
import { History } from "history";

export interface ModuleRepresentation {
  readonly data: HomepageModule;
  readonly id: string;
}

class State {
  static default: State = new State([], [], "", []);
  constructor(
    readonly originalModules: ModuleRepresentation[],
    readonly modules: ModuleRepresentation[],
    readonly selectedTab: string,
    readonly tabs: string[]
  ) {}

  copy(overrides: Partial<State>): State {
    return new State(
      overrides.originalModules ?? this.originalModules,
      overrides.modules ?? this.modules,
      overrides.selectedTab ?? this.selectedTab,
      overrides.tabs ?? this.tabs
    );
  }
}

class HomepageViewerViewModel {
  state: State = State.default;
  private originalDocument: HomepageDocument = {};

  constructor(
    private readonly editorViewModel: EditorViewModel,
    private readonly history: History
  ) {
    this.updateState(this.editorViewModel.originalObject);
    reaction(
      () => this.editorViewModel.state.selectedStore,
      () => {
        const selectedTable = this.editorViewModel.state.selectedStore?.tables?.find(
          (table) => table.key === this.editorViewModel.state.selectedTable
        );
        if (
          selectedTable &&
          selectedTable.type === "Homepage" &&
          !lodash.isEqual(this.originalDocument, selectedTable.document)
        ) {
          this.updateState(this.editorViewModel.originalObject);
        }
      }
    );
    makeObservable<HomepageViewerViewModel, "updateState" | "originalDocument">(
      this,
      {
        state: observable,
        originalDocument: observable,
        updateState: action.bound,
        onCurrentModuleOrderChanged: action.bound,
        onSelectedDocumentKeyChanged: action.bound,
        onNewModuleCreated: action.bound,
        onEditDone: action.bound,
      }
    );
  }

  onSelectedDocumentKeyChanged(newKey: string): void {
    const currentHomepageDocument = JSON.parse(
      this.editorViewModel.state.currentObject
    ) as HomepageDocument;
    const originalHomepageDocument = this.editorViewModel
      .originalObject as HomepageDocument;
    this.state = this.state.copy({
      selectedTab: newKey,
      modules: this.mapModulesToRepresentation(
        currentHomepageDocument.homepages?.[newKey] ?? []
      ),
      originalModules: this.mapModulesToRepresentation(
        originalHomepageDocument.homepages?.[newKey] ?? []
      ),
    });
  }

  onCurrentModuleOrderChanged(
    moduleRepresentations: ModuleRepresentation[]
  ): void {
    const modules = moduleRepresentations.map((item) => item.data);
    const updateDocument: HomepageDocument = {
      ...this.originalDocument,
      items: this.originalDocument.items !== undefined ? modules : undefined,
      homepages:
        this.originalDocument.homepages !== undefined
          ? {
              ...this.originalDocument.homepages,
              [this.state.selectedTab]: modules,
            }
          : undefined,
    };
    this.editorViewModel.onCurrentDocumentChanged(
      JSON.stringify(updateDocument, null, 2)
    );
    this.state = this.state.copy({
      modules: moduleRepresentations,
    });
  }

  private updateState(homepageDocument: Record<string, unknown>) {
    this.originalDocument = homepageDocument;
    if (this.originalDocument.items) {
      this.state = this.state.copy({
        tabs: [],
        selectedTab: "",
        modules: this.mapModulesToRepresentation(this.originalDocument.items),
        originalModules: this.mapModulesToRepresentation(
          this.originalDocument.items
        ),
      });
    } else {
      const tabs = Object.keys(this.originalDocument.homepages ?? {});
      const originalModulesForSelectedHomepage = this.originalDocument
        .homepages?.[tabs[0]];
      const currentModulesForSelectedHomepage = this.originalDocument
        .homepages?.[tabs[0]];
      if (
        originalModulesForSelectedHomepage &&
        currentModulesForSelectedHomepage
      ) {
        this.state = this.state.copy({
          tabs: tabs,
          selectedTab: tabs[0],
          modules: this.mapModulesToRepresentation(
            currentModulesForSelectedHomepage
          ),
          originalModules: this.mapModulesToRepresentation(
            originalModulesForSelectedHomepage
          ),
        });
      }
    }
  }

  private mapModulesToRepresentation(
    modules: HomepageModule[]
  ): { id: string; data: HomepageModule }[] {
    return (
      modules.map((item, index) => {
        return {
          data: item,
          id: JSON.stringify(index),
        };
      }) ?? []
    );
  }

  onNewModuleCreated(newModel: Record<string, unknown>): void {
    const currentDocument = JSON.parse(
      this.editorViewModel.state.currentObject
    ) as HomepageDocument;
    if (currentDocument.items) {
      currentDocument.items.push(newModel as HomepageModule);
    } else if (currentDocument.homepages) {
      const currentHomepage = currentDocument.homepages[this.state.selectedTab];
      currentHomepage.push(newModel as HomepageModule);
    }
    this.editorViewModel.onCurrentDocumentChanged(
      JSON.stringify(currentDocument, null, 2)
    );
    this.state = this.state.copy({
      modules: [
        ...this.state.modules,
        { id: "", data: newModel as HomepageModule },
      ],
    });
    this.history.goBack();
  }

  onRemoveExpiredModules(): void {
    const filteredModules: ModuleRepresentation[] = this.state.modules.filter(
      (item) => {
        const endTime = item.data.endTime
          ? moment.unix(item.data.endTime)
          : null;
        const currentTime = moment.utc();
        return endTime === null || endTime?.isAfter(currentTime);
      }
    );
    const currentDocument = JSON.parse(
      this.editorViewModel.state.currentObject
    ) as HomepageDocument;
    const updatedDocument: HomepageDocument = currentDocument.items
      ? {
          ...currentDocument,
          items: filteredModules.map((item) => item.data),
        }
      : {
          ...currentDocument,
          homepages: {
            ...currentDocument.homepages,
            [this.state.selectedTab]: filteredModules.map((item) => item.data),
          },
        };
    this.editorViewModel.onCurrentDocumentChanged(
      JSON.stringify(updatedDocument, null, 2)
    );
    this.state = this.state.copy({
      modules: filteredModules,
    });
  }

  onEditDone(updatedModule: HomepageModule, indexOfEditedModule: number): void {
    const moduleToEdit = this.state.modules[indexOfEditedModule];
    if (indexOfEditedModule > -1 && moduleToEdit) {
      const updatedModules = this.state.modules;
      const updatedModuleRepresentation: ModuleRepresentation = {
        ...moduleToEdit,
        data: updatedModule,
      };
      updatedModules.splice(indexOfEditedModule, 1);
      updatedModules.splice(
        indexOfEditedModule,
        0,
        updatedModuleRepresentation
      );

      const currentDocument = JSON.parse(
        this.editorViewModel.state.currentObject
      ) as HomepageDocument;
      if (currentDocument.items) {
        currentDocument.items = this.state.modules.map((item) => item.data);
      } else if (currentDocument.homepages) {
        currentDocument.homepages[
          this.state.selectedTab
        ] = this.state.modules.map((item) => item.data);
      }
      this.editorViewModel.onCurrentDocumentChanged(
        JSON.stringify(currentDocument, null, 2)
      );

      this.state = this.state.copy({
        modules: updatedModules,
      });
    }
    this.history.goBack();
  }
}

export default HomepageViewerViewModel;
