import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { SelectorOption } from "../../components/Selector";
export interface SyncSideViewState {
  isLoading: boolean;
  syncType: string;
}

class SyncSideViewModel {
  state: SyncSideViewState = {
    isLoading: false,
    syncType: "",
  };

  constructor(private readonly syncs: SelectorOption[]) {
    makeObservable(this, {
      state: observable,
      isDisabled: computed,
      syncOptions: computed,
      onSyncTypeSelected: action.bound,
      onSyncClicked: action.bound,
    });
  }

  get syncOptions(): SelectorOption[] {
    return this.syncs;
  }

  get isDisabled(): boolean {
    const isStateComplete = this.state.syncType.length > 0 ? true : false;
    const isSubmitting = this.state.isLoading;
    return !isStateComplete || isSubmitting;
  }

  private setSubmitState(isLoading: boolean): void {
    runInAction(() => {
      this.state = { ...this.state, isLoading };
    });
  }

  onSyncTypeSelected(value: string): void {
    this.state = { ...this.state, syncType: value };
  }

  async onSyncClicked(baseUrl: string, storeKey?: string): Promise<void> {
    const url = `${baseUrl}${this.state.syncType}?storekey=${storeKey}`;
    this.setSubmitState(true);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          "Cache-Control": "no-cache",
        },
      });
      if (response.status === 200) {
        alert(`Sync successfully triggered!`);
      }
    } catch (error) {
      this.setSubmitState(false);
      alert("Failed to trigger sync. Please try again. ");
    } finally {
      this.setSubmitState(false);
    }
    return;
  }
}

export default SyncSideViewModel;
