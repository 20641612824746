import React from "react";
import styles from "./styles/Index.module.css";
import HomepageViewer from "./HomepageViewer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ServiceLoaderContext } from "../index";

interface TableViewerProps {
  readonly tableName: string;
}

const TableViewer: React.FunctionComponent<TableViewerProps> = ({
  ...props
}) => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => (
        <div className={styles.container}>
          {props.tableName === "homepages" && (
            <DndProvider backend={HTML5Backend}>
              <HomepageViewer viewModel={serviceLoader.homepageViewModel} />
            </DndProvider>
          )}
        </div>
      )}
    </ServiceLoaderContext.Consumer>
  );
};

export default TableViewer;
