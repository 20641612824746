import { EditableModule } from "./Modules";
import { FormField } from "./FormItems";
import { FieldGroup } from "./FieldGroup";

export type FormFieldWithError = {
  readonly formField: FormField;
  readonly error: string;
};

export interface RefType {
  readonly fields: FieldGroup;
  readonly linking: FieldGroup;
  readonly scheduling: FieldGroup;
}

export type RefTypeKey = keyof RefType;
export class AddModuleState {
  static default = new AddModuleState(
    false,
    false,
    false,
    false,
    {
      fields: new FieldGroup([], [], []),
      linking: new FieldGroup([], [], []),
      scheduling: new FieldGroup([], [], []),
    },
    []
  );

  constructor(
    readonly submitting: boolean,
    readonly startTimeEnabled: boolean,
    readonly endTimeEnabled: boolean,
    readonly linkingEnabled: boolean,
    readonly refs: RefType,
    readonly errors: FormFieldWithError[],
    readonly selectedModule?: EditableModule
  ) {}

  copy(overrides: Partial<AddModuleState>): AddModuleState {
    return new AddModuleState(
      overrides.submitting ?? this.submitting,
      overrides.startTimeEnabled ?? this.startTimeEnabled,
      overrides.endTimeEnabled ?? this.endTimeEnabled,
      overrides.linkingEnabled ?? this.linkingEnabled,
      overrides.refs ?? this.refs,
      overrides.errors ?? this.errors,
      overrides.selectedModule ?? this.selectedModule
    );
  }
}
