import React from "react";
import { ServiceLoaderContext } from "../index";

const UserObserver: React.FunctionComponent = () => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => {
        serviceLoader.userObserverViewModel;
        return <></>;
      }}
    </ServiceLoaderContext.Consumer>
  );
};

export default UserObserver;
