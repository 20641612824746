import React from "react";
import * as Firebase from "firebase";
import { useObserver } from "mobx-react";
import Login from "../Login";
import Editor from "../Editor";
import AppViewModel from "./AppViewModel";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { History } from "history";
import StoreSelector from "../StoreSelector";

interface AppProps {
  readonly prodFirebaseApp: Firebase.app.App;
  readonly currentFirebaseApp: Firebase.app.App;
  readonly viewModel: AppViewModel;
  readonly isProduction: boolean;
  readonly history: History;
}

const App: React.FunctionComponent<AppProps> = ({ ...props }) => {
  return useObserver(() => {
    if (props.viewModel.isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <Router history={props.history}>
        <Switch>
          <Route path="/login">
            <Login onLoginComplete={props.viewModel.onLoginComplete} />
          </Route>
          <Route path="/:store/:table">
            <Editor
              isProduction={props.isProduction}
              currentFirebaseApp={props.currentFirebaseApp}
              prodFirebaseApp={props.prodFirebaseApp}
            />
          </Route>
          <Route exact path="/:store">
            <StoreSelector />
          </Route>
          <Route exact path="/">
            <StoreSelector />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  });
};

export default App;
