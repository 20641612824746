import React from "react";

interface ImageModulePreviewProps {
  readonly data: Record<string, unknown>;
}

interface Config {
  readonly attributes: {
    readonly imageUrl: string;
    readonly backgroundColor: string;
    readonly padding: number;
  };
  readonly heightMultiplier: number;
}

const ImageModulePreview: React.FC<ImageModulePreviewProps> = ({
  ...props
}) => {
  const config: Config = (props.data as unknown) as Config;
  return (
    <img
      style={{
        margin: "auto",
        maxHeight: `200px`,
        marginBottom: "20px",
        width: "auto",
        backgroundColor: config.attributes.backgroundColor,
      }}
      src={config.attributes.imageUrl}
      alt="VImageWithPadding"
    />
  );
};

export default ImageModulePreview;
