import React, { FC, useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from "react-dnd";
import { HomepageItemType } from "./HomepageItemType";
import styles from "../styles/HomepageViewer.module.css";
import * as ld from "lodash";
import * as moment from "moment-timezone";
import { ModuleRepresentation } from "./HomepageViewerViewModel";
import ModulePreview from "../../ModulePreview";
import { ModuleType } from "../../AddModule/Modules";

/*
Drag and drop logic is taken from React DnD simple sortable example
https://react-dnd.github.io/react-dnd/examples/sortable/simple
 */

interface HomepageModuleCardProps {
  currentRepresentation: { data: ModuleRepresentation; index: number };
  originalRepresentation: { data: ModuleRepresentation; index: number };
  onDeleteClicked: () => void;
  onEditClicked: () => void;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const HomepageModuleCard: FC<HomepageModuleCardProps> = ({
  originalRepresentation,
  currentRepresentation,
  onDeleteClicked,
  onEditClicked,
  moveCard,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: HomepageItemType.ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = currentRepresentation.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: HomepageItemType.ITEM,
    item: () => {
      return {
        id: currentRepresentation.data.id,
        index: currentRepresentation.index,
      };
    },
    collect: (monitor: { isDragging: () => boolean }) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const endTime = currentRepresentation.data.data.endTime
    ? moment.unix(currentRepresentation.data.data.endTime)
    : null;
  const startTime = currentRepresentation.data.data.startTime
    ? moment.unix(currentRepresentation.data.data.startTime)
    : null;
  const currentTime = moment.utc();
  return (
    <div
      ref={ref}
      className={`
      ${styles.homepageModule} 
      ${endTime?.isSameOrBefore(currentTime) ? styles.expired : ""}
      ${startTime?.isAfter(currentTime) ? styles.starting : ""}`}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <ModulePreview
        moduleType={currentRepresentation.data.data.moduleType as ModuleType}
        data={currentRepresentation.data.data}
      />
      <div className={styles.homepageModuleContent}>
        <div
          className={`${startTime?.isAfter(currentTime) ? styles.column : ""}`}
        >
          <span>{`${originalRepresentation.index}. ${currentRepresentation.data.data.moduleType}`}</span>
          {endTime?.isSameOrBefore(currentTime) && (
            <span className={styles.expiredTag}>Expired</span>
          )}
          {startTime?.isAfter(currentTime) && (
            <span>
              Displaying on: <strong>{startTime?.format("DD/MM")}</strong>
              &nbsp;at&nbsp;
              <strong>{startTime?.format("HH:mm")}</strong>
            </span>
          )}
          {endTime?.isAfter(currentTime) && (
            <span>
              Expiring on: <strong>{endTime?.format("DD/MM")}</strong>
              &nbsp;at&nbsp;
              <strong>{endTime?.format("HH:mm")}</strong>
            </span>
          )}
        </div>
        <div>
          <button onClick={onEditClicked} className={styles.button}>
            Edit
          </button>
          <button onClick={onDeleteClicked} className={styles.button}>
            Delete
          </button>
          {originalRepresentation.index === -1 ? (
            <span className={styles.homepageModuleChangedLabel}>New</span>
          ) : (
            !ld.isEqual(currentRepresentation, originalRepresentation) && (
              <span className={styles.homepageModuleChangedLabel}>Changed</span>
            )
          )}
        </div>
      </div>
    </div>
  );
};
