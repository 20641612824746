import React from "react";

interface SpacerModulePreview {
  readonly data: Record<string, unknown>;
}

interface Config {
  readonly attributes: {
    readonly spacerBackgroundColor: {
      readonly hex: string;
    };
  };
  readonly height: number;
}

const SpacerModulePreview: React.FC<SpacerModulePreview> = ({ ...props }) => {
  const config = (props.data as unknown) as Config;
  return (
    <div
      style={{
        height: config.height,
        backgroundColor: config.attributes.spacerBackgroundColor.hex,
        marginBottom: "20px",
      }}
    />
  );
};

export default SpacerModulePreview;
