import InputProcessor from "./InputProcessor";
import { FormField } from "./FormItems";

const oneMBinBytes = 1048576;

class ImageInputProcessor implements InputProcessor {
  constructor(
    private readonly apiBaseUrl: string,
    private readonly storekey: string
  ) {}
  async process(
    formField: FormField,
    inputField: HTMLInputElement
  ): Promise<string | number | boolean> {
    const file = inputField.files?.[0];
    if (file !== undefined && file.size <= oneMBinBytes) {
      const cleanedFileName = file.name
        .split("\\")
        .slice(-1)[0]
        .replaceAll(" ", "");
      const formData = new FormData();
      formData.append("file", file, cleanedFileName);
      const response = await fetch(
        `${this.apiBaseUrl}assets/upload?storekey=${this.storekey}`,
        {
          method: "POST",
          headers: {
            authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          },
          body: formData,
        }
      );
      if (response.status === 200) {
        return (await response.json())[0];
      } else {
        throw Error("Unknown error when uploading image");
      }
    }
    if (formField.required) {
      throw Error("An image file is required!");
    }
    throw Error("There was an error processing the image!");
  }
}

export default ImageInputProcessor;
