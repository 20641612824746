import * as firebase from "firebase/app";
import { action, makeObservable, observable, runInAction } from "mobx";

export interface SearchOptions {
  readonly key: string;
  readonly operator: firebase.firestore.WhereFilterOp;
  readonly collection: string;
}

interface SearchSideViewState {
  readonly result: string;
}

class SearchSideViewViewModel {
  state: SearchSideViewState = {
    result: "",
  };

  constructor(
    private readonly firebaseApp: firebase.app.App,
    private readonly option: SearchOptions,
    private readonly selectedStore: string
  ) {
    makeObservable(this, {
      state: observable,
      onSubmitClicked: action.bound,
    });
  }

  async onSubmitClicked(searchTerm: string): Promise<void> {
    try {
      if (
        this.selectedStore !== "" &&
        searchTerm !== "" &&
        this.option.collection !== ""
      ) {
        const sanitisedSearchTerms = SearchSideViewViewModel.sanitise(
          searchTerm
        );
        const result = await this.firebaseApp
          .firestore()
          .collection("shopifyStores")
          .doc(this.selectedStore)
          .collection(this.option.collection)
          .where(this.option.key, this.option.operator, sanitisedSearchTerms)
          .get();
        runInAction(() => {
          this.state = {
            ...this.state,
            result: result.docs[0].data()?.id,
          };
        });
      }
    } catch (e) {
      console.log(`Error searching ${this.option.collection}`, e);
      alert(`error searching for ${this.option.collection}`);
    }
  }

  private static sanitise(searchTerm: string): string {
    const split = searchTerm.split("/");
    if (split.length > 1) {
      return split[split.length - 1];
    } else {
      return searchTerm;
    }
  }
}

export default SearchSideViewViewModel;
