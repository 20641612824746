import { action, makeObservable, observable } from "mobx";

interface State {
  readonly result: string;
  readonly hasError: boolean;
  readonly submitting: boolean;
}

class AppBuilderViewModel {
  state: State = {
    result: "",
    hasError: false,
    submitting: false,
  };

  constructor(
    private readonly firebaseApp: firebase.app.App,
    private readonly storekey?: string,
    private readonly apiBaseUrl?: string
  ) {
    this.firebaseApp = firebaseApp;
    this.storekey = storekey;
    this.apiBaseUrl = apiBaseUrl;
    makeObservable(this, {
      state: observable,
      onSubmitClicked: action.bound,
    });
  }

  async onSubmitClicked(): Promise<void> {
    const docRef = await this.firebaseApp
      .firestore()
      .collection("androidAppConfig")
      .doc(this.storekey)
      .get();

    this.state = {
      ...this.state,
      submitting: true,
    };
    let result = null;
    let error = null;
    try {
      const response = await fetch(
        `${this.apiBaseUrl}apps/android/${docRef.data()?.flavorName}/build`,
        {
          method: "GET",
          headers: {
            authorization: "786d2164-a918-4600-9a36-dd95134c7685",
          },
        }
      );

      console.log(response.text);
      if (response.status >= 200 && response.status <= 300) result = "OK";
      else {
        error = response.statusText;
      }
    } catch (error) {}
    this.state = {
      result:
        typeof result === "string"
          ? result
          : typeof error === "string"
          ? error
          : "",
      submitting: false,
      hasError: error !== null,
    };
  }
}

export default AppBuilderViewModel;
