import React, { useEffect, useState } from "react";
import { Form, FormControl } from "react-bootstrap";
import * as moment from "moment-timezone";
import Selector from "../components/Selector";
import styles from "./styles/FormControlTime.module.css";

const dateTimeFormat = `${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME}`;
const dateFormat = moment.HTML5_FMT.DATE;
const timeFormat = moment.HTML5_FMT.TIME;

interface FormControlTime {
  readonly initialValue?: number;
  readonly disabled?: boolean;
  readonly error?: string;
}

const FormControlTime = React.forwardRef<HTMLInputElement, FormControlTime>(
  (props, ref) => {
    type State = {
      readonly date: string;
      readonly time: string;
      readonly selectedTimezone: string;
    };
    const [state, setState] = useState<State>({
      date: "",
      time: "",
      selectedTimezone: "Europe/London",
    });
    useEffect(() => {
      if (props.initialValue) {
        const time = moment.unix(props.initialValue);
        const formattedDate = time.format(dateFormat);
        const formattedTime = time.format(timeFormat);
        setState({
          date: formattedDate,
          time: formattedTime,
          selectedTimezone: time.tz() ?? state.selectedTimezone,
        });
      }
    }, [props.initialValue]);
    return (
      <div className={styles.container}>
        <Form.Control
          value={state.date}
          disabled={props.disabled}
          placeholder="dd / mm /yyyy"
          style={{
            width: 0,
            flexGrow: 1,
          }}
          onChange={(event) => {
            setState((prev) => {
              return {
                ...prev,
                date: event.target.value,
              };
            });
          }}
          type="date"
        />
        <Form.Control
          value={state.time}
          disabled={props.disabled}
          placeholder="hh : mm"
          style={{
            width: 0,
            flexGrow: 1,
          }}
          onChange={(event) => {
            setState((prev) => {
              return {
                ...prev,
                time: event.target.value,
              };
            });
          }}
          type="time"
        />
        <div
          style={{
            marginLeft: "5px",
            flexGrow: 1,
          }}
        >
          <Selector
            isDisabled={props.disabled}
            currentValue={state.selectedTimezone}
            placeholder="Timezone"
            options={moment.tz.names().map((timezone) => {
              return {
                value: timezone,
                label: timezone,
              };
            })}
            onValueChanged={(newValue) => {
              setState({
                ...state,
                selectedTimezone: newValue,
              });
            }}
          />
        </div>
        <input
          value={moment
            .tz(
              `${state.date} ${state.time}`,
              dateTimeFormat,
              state.selectedTimezone
            )
            .unix()}
          hidden={true}
          type="number"
          ref={ref}
          readOnly={true}
        />
        {props.error && (
          <FormControl.Feedback type="invalid">
            {props.error}
          </FormControl.Feedback>
        )}
      </div>
    );
  }
);

export default FormControlTime;
