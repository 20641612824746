import React from "react";
import styles from "../styles/ActiveUsers.module.css";
import { ServiceLoaderContext } from "../index";
import { useObserver } from "mobx-react";
import ActiveUsersViewModel from "./ActiveUsersViewModel";

const _ActiveUsers: React.FunctionComponent<{
  viewModel: ActiveUsersViewModel;
}> = ({ ...props }) => {
  return useObserver(() => (
    <>
      <h6>Active users</h6>
      <div className={styles.usersContainer}>
        {props.viewModel.state
          .filter((user) => user.currentPage !== "" && user.currentStore !== "")
          .map((user, index) => (
            <p key={index}>
              {user.email} - {user.currentStore} - {user.currentPage}
            </p>
          ))}
      </div>
    </>
  ));
};

const ActiveUsers: React.FunctionComponent = () => (
  <ServiceLoaderContext.Consumer>
    {(serviceLoader) => {
      return <_ActiveUsers viewModel={serviceLoader.activeUsersViewModel} />;
    }}
  </ServiceLoaderContext.Consumer>
);
export default ActiveUsers;
