export {};

declare global {
  interface Array<T> {
    replaceItem(newValue: T, predicate: (element: T) => boolean): Array<T>;
    mapNotNull<R>(mapper: (element: T) => R | null | undefined): Array<R>;
  }
}

Array.prototype.replaceItem = function <T>(
  newValue: T,
  predicate: (element: T) => boolean
): Array<T> {
  const indexToBeReplaced = this.findIndex(predicate);
  if (indexToBeReplaced !== -1) {
    this.splice(indexToBeReplaced, 1);
    this.splice(indexToBeReplaced, 0, newValue);
  }
  return this;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Array.prototype.mapNotNull = function <T, R>(
  mapper: (element: T) => R | null | undefined
) {
  return this.map((item) => mapper(item)).filter(
    (it) => it !== null && it !== undefined
  );
};
