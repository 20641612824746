import React, { useRef } from "react";
import Button from "../../components/Button";
import styles from "./DashboardLinkSideView.module.css";

const DashboardSyncSideView: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onOpenDashboard = () => {
    if (inputRef?.current?.value) {
      const shopname = inputRef?.current?.value.replace(".myshopify.com", "");
      // This way, both including and excluding .myshopify.com would work.
      window.open(
        `https://app.vennapps.com/?shop=${shopname}.myshopify.com&bypassSecurity=pinkpanda38`
      );
    }
  };

  return (
    <div className={styles.dashboardLinkSideView}>
      <div className={styles.dashboardLinkContainer}>
        <label htmlFor="shop-name">Shopify Shop Name</label>
        <input
          className={styles.input}
          ref={inputRef}
          id="shop-name"
          placeholder="example: venn-garden"
          type="text"
        />
        <p className={styles.warning}>
          Opens a <span className={styles.underline}>PRODUCTION</span> version
          of the Dashboard! Please do not make any changes, save or publish
          anything. All changes are visible for our users.
        </p>
        <Button label="Go to Dashboard" onClick={onOpenDashboard} />
      </div>
    </div>
  );
};

export default DashboardSyncSideView;
