import React from "react";
import SearchSideView from "./SearchSideView";
import { ServiceLoaderContext } from "../index";
import FileUploader from "./FileUploader";
import SyncSideView from "./SyncSide";
import DashboardSyncSideView from "./DashboardLinkSideView";
import AppBuilder from "./AppBuilder";
import AddStorekeySideView from "./AddStorekeySideView";

const SearchContainer: React.FunctionComponent = () => {
  return (
    <>
      <h6>Utilities</h6>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <FileUploader viewModel={serviceLoader.fileUploaderViewModel} />
        )}
      </ServiceLoaderContext.Consumer>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <AppBuilder viewModel={serviceLoader.appBuilderViewModel} />
        )}
      </ServiceLoaderContext.Consumer>
      <h6>Search by handle</h6>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <SearchSideView
            viewModel={serviceLoader.searchSideViewModel({
              operator: "==",
              key: "handle",
              collection: "collections",
            })}
            title="Collections"
          />
        )}
      </ServiceLoaderContext.Consumer>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <SearchSideView
            viewModel={serviceLoader.searchSideViewModel({
              operator: "==",
              key: "handle",
              collection: "products",
            })}
            title="Products"
          />
        )}
      </ServiceLoaderContext.Consumer>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <SearchSideView
            viewModel={serviceLoader.searchSideViewModel({
              operator: "==",
              key: "handle",
              collection: "articles",
            })}
            title="Articles"
          />
        )}
      </ServiceLoaderContext.Consumer>
      <h6>Syncs</h6>
      <ServiceLoaderContext.Consumer>
        {(serviceLoader) => (
          <SyncSideView
            viewModel={serviceLoader.syncSideViewModel()}
            editorViewModel={serviceLoader.editorViewModel}
          />
        )}
      </ServiceLoaderContext.Consumer>
      <h6>Dashboards</h6>
      <DashboardSyncSideView />
      <h6>Add Storekey</h6>
      <ServiceLoaderContext.Consumer>
        {(servicLoader) => (
          <AddStorekeySideView editorViewModel={servicLoader.editorViewModel} />
        )}
      </ServiceLoaderContext.Consumer>
    </>
  );
};

export default SearchContainer;
