import React from "react";
import styles from "../styles/Editor.module.css";
import MonacoEditor from "react-monaco-editor";
import * as Firebase from "firebase/app";
import UserObserver from "../UserObserver";
import Button from "../components/Button";
import ActiveUsers from "../ActiveUsers";
import SearchContainer from "../SearchContainer";
import TableViewer from "../TableViewer";
import EditorViewModel from "./EditorViewModel";
import { ServiceLoaderContext } from "../index";
import { useObserver } from "mobx-react";
import AddModule from "../AddModule";
import StoreSelector from "../StoreSelector";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useState } from "react";

interface EditorProps {
  readonly prodFirebaseApp: Firebase.app.App;
  readonly currentFirebaseApp: Firebase.app.App;
  readonly isProduction: boolean;
}

const _Editor: React.FunctionComponent<
  EditorProps & { viewModel: EditorViewModel }
> = ({ ...props }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);

  return useObserver(() => (
    <div className={styles.container}>
      <StoreSelector />
      <UserObserver />
      <div className={styles.buttonContainer}>
        <Button
          isDisabled={
            props.viewModel.state.selectedStore === undefined || isSaving
          }
          label="Save"
          onClick={async () => {
            setIsSaving(true);
            const result = await props.viewModel.onSaveClicked();
            if (result) {
              alert("Document saved Successfully");
            } else {
              alert("Failed to save document");
            }
            setIsSaving(false);
          }}
        />
        {!props.isProduction && (
          <Button
            isDisabled={
              props.viewModel.state.selectedStore === undefined || isSaving
            }
            label="Deploy to production"
            onClick={async () => {
              setIsSaving(true);
              const result = await props.viewModel.onDeployClicked();
              if (result) {
                alert("Document deployed Successfully");
              } else {
                alert("Failed to deploy document");
              }
              setIsSaving(false);
            }}
          />
        )}
      </div>
      {props.viewModel.hasUnsavedChanged && (
        <div className={styles.unsavedChangesAlert}>
          <span>You have unsaved changes</span>
        </div>
      )}
      <div className={styles.tabContainer}>
        <button
          className={`${styles.tab} ${
            !window.location.href.includes("viewer") ? styles.active : ""
          }`}
          onClick={() => {
            history.push(`${url}`);
          }}
        >
          Editor
        </button>
        {props.viewModel.state.selectedTable === "homepages" && (
          <button
            className={`${styles.tab} ${
              window.location.href.includes("viewer") ? styles.active : ""
            }`}
            onClick={() => {
              history.push(`${url}/viewer`);
            }}
          >
            Viewer
          </button>
        )}
      </div>
      <div className={styles.editorContainer}>
        <Switch>
          <Route path={`${url}/viewer`}>
            <Switch>
              <Route exact path={`${url}/viewer/add`}>
                <AddModule />
              </Route>
              <Route>
                <TableViewer tableName={props.viewModel.state.selectedTable} />
              </Route>
            </Switch>
          </Route>
          <Route exact path={`${url}`}>
            <MonacoEditor
              width={props.viewModel.editorDimensions.width}
              height={props.viewModel.editorDimensions.height}
              language="json"
              value={props.viewModel.state.currentObject}
              options={{
                selectOnLineNumbers: true,
                automaticLayout: true,
              }}
              onChange={props.viewModel.onCurrentDocumentChanged}
            />
          </Route>
        </Switch>
        <div className={styles.searchViewContainer}>
          {window.location.href.endsWith("viewer") && (
            <Button
              label="New Module"
              onClick={() => {
                history.push(`${url}/viewer/add`);
              }}
            />
          )}
          <ActiveUsers />
          {props.viewModel.state.selectedStore && <SearchContainer />}
        </div>
      </div>
    </div>
  ));
};

const Editor: React.FunctionComponent<EditorProps> = ({ ...props }) => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => {
        return <_Editor {...props} viewModel={serviceLoader.editorViewModel} />;
      }}
    </ServiceLoaderContext.Consumer>
  );
};

export default Editor;
