import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import Selector from "../components/Selector";
import styles from "./styles/FormControlSelect.module.css";

interface FormControlSelect {
  readonly initialValue?: string;
  readonly disabled?: boolean;
  readonly error?: string;
  readonly options: string[];
  readonly placeholder: string;
}

const FormControlSelect = React.forwardRef<HTMLInputElement, FormControlSelect>(
  (props, ref) => {
    type State = {
      readonly value: string;
    };
    const [state, setState] = useState<State>({
      value: "",
    });
    useEffect(() => {
      if (props.initialValue) {
        setState({
          value: props.initialValue,
        });
      }
    }, [props.initialValue]);
    return (
      <div className={styles.container}>
        <Selector
          isDisabled={props.disabled}
          currentValue={state.value}
          placeholder={props.placeholder}
          options={props.options.map((option) => {
            return { value: option, label: option };
          })}
          onValueChanged={(event) => {
            setState({ value: event });
          }}
        />
        <input
          value={state.value}
          hidden={true}
          type="string"
          ref={ref}
          readOnly={true}
        />
        {props.error && (
          <FormControl.Feedback type="invalid">
            {props.error}
          </FormControl.Feedback>
        )}
      </div>
    );
  }
);

export default FormControlSelect;
