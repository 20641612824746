import React from "react";
import style from "./styles/Button.module.css";

interface ButtonProps {
  readonly label: React.ReactNode;
  readonly onClick: () => void;
  readonly isDisabled?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({ ...props }) => (
  <button
    className={style.button}
    disabled={props.isDisabled ?? false}
    onClick={props.onClick}
  >
    {props.label}
  </button>
);

export default Button;
