import { FormField, FormType } from "./FormItems";
import InputProcessor from "./InputProcessor";

class InputProcessorImpl implements InputProcessor {
  constructor(
    private readonly inputProcessors: {
      type: FormType;
      processor: InputProcessor;
    }[]
  ) {}
  process(
    formField: FormField,
    inputField: HTMLInputElement
  ): Promise<string | number | boolean> {
    const processed = this.inputProcessors
      .find((item) => item.type === formField.type)
      ?.processor.process(formField, inputField);
    if (processed) {
      return processed;
    }
    return new Promise((resolve, reject) => {
      const asNumber = Number(inputField.value);
      let value: string | number | boolean = inputField.value;
      if (
        !isNaN(asNumber) &&
        (formField.type === "number" || formField.type === "datetime-local")
      ) {
        value = asNumber;
      }
      if (value === "true") {
        value = true;
      }
      if (value === "false") {
        value = false;
      }
      if (
        (inputField.value === "" || inputField.value === undefined) &&
        formField.required
      ) {
        reject(Error(`${formField.label} is required`));
        return;
      }
      if (formField.validation && !formField.validation(value)) {
        reject(Error(`${formField.label} is invalid`));
        return;
      }
      resolve(value);
    });
  }
}

export default InputProcessorImpl;
