import ImageInputProcessor from "../../AddModule/ImageInputProcessor";
import { action, makeObservable, observable } from "mobx";
import { FormField } from "../../AddModule/FormItems";

const fakeFormField: FormField = {
  description: "",
  isAttribute: false,
  jsonKey: "",
  label: "",
  multipleInput: false,
  required: false,
  type: "file",
};

interface State {
  readonly result: string;
  readonly hasError: boolean;
  readonly submitting: boolean;
}

class FileUploaderViewModel {
  state: State = {
    result: "",
    hasError: false,
    submitting: false,
  };
  constructor(private readonly imageInputProcessor: ImageInputProcessor) {
    makeObservable(this, {
      state: observable,
      onSubmitClicked: action.bound,
    });
  }

  async onSubmitClicked(element: HTMLInputElement): Promise<void> {
    this.state = {
      ...this.state,
      submitting: true,
    };
    let processingResult = null;
    try {
      processingResult = await this.imageInputProcessor.process(
        fakeFormField,
        element
      );
    } catch (error) {}
    this.state = {
      result: typeof processingResult === "string" ? processingResult : "",
      submitting: false,
      hasError:
        processingResult === null || typeof processingResult !== "string",
    };
  }
}

export default FileUploaderViewModel;
