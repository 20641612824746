import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { FormField, FormType } from "./FormItems";
import React from "react";
import { LinkConfigForm, SchedulingConfigForm } from "./Modules";
import lodash from "lodash";
import "../util/ArrayExtensions";

const castValue = (
  type: FormType,
  value: unknown
): string | number | boolean => {
  if (type === "color" && typeof value === "object") {
    return (value as Record<string, string>).hex;
  }
  if (type === "number" || type === "datetime-local") {
    return value as number;
  }

  if (typeof value === "boolean") {
    return value as boolean;
  }

  return value as string;
};

const groupExistingRefs = (
  formFields: FormFieldWithRefs[]
): FormFieldWithRefs[] => {
  const groupedFieldWithRefs: FormFieldWithRefs[] = [];
  const existingFields: Record<string, boolean> = {};

  formFields.forEach((formField) => {
    if (!existingFields[formField.formItem.jsonKey]) {
      groupedFieldWithRefs.push(formField);
      existingFields[formField.formItem.jsonKey] = true;
    } else {
      const field = groupedFieldWithRefs.find(
        (fieldWithRef) =>
          fieldWithRef.formItem.jsonKey === formField.formItem.jsonKey
      );
      if (field) {
        formField.refs.forEach((ref) => field.refs.push(ref));
      }
    }
  });

  return groupedFieldWithRefs;
};

const parseInitialValue = (
  type: FormType,
  value: unknown
): (string | number | boolean)[] => {
  return Array.isArray(value)
    ? (value as Array<unknown>).mapNotNull((valueItem) =>
        castValue(type, valueItem)
      )
    : [castValue(type, value)];
};

const generateFieldRefs = (
  fields: FormField[],
  existingData: Record<string, unknown>,
  supportedLanguages: string[],
  existingDataPerLanguage: { language: string; data: Record<string, unknown> }[]
): FormFieldWithRefs[] => {
  return fields.map((formField) => {
    const attributes = existingData["attributes"] as Record<string, unknown>;
    const defaultValue = formField.isAttribute
      ? attributes?.[formField.jsonKey] === undefined
        ? []
        : parseInitialValue(formField.type, attributes[formField.jsonKey])
      : existingData[formField.jsonKey] === undefined
      ? []
      : parseInitialValue(formField.type, existingData[formField.jsonKey]);
    return {
      formItem: formField,
      refs: [
        {
          languageCode: "en",
          refs:
            defaultValue.length === 0
              ? [
                  {
                    ref: React.createRef<HTMLInputElement>(),
                    initialValue: undefined,
                  },
                ]
              : defaultValue.map((value) => {
                  return {
                    ref: React.createRef<HTMLInputElement>(),
                    initialValue: value,
                  };
                }),
        },
        ...existingDataPerLanguage.mapNotNull((languageData) => {
          const valueObject = formField.isAttribute
            ? (languageData.data["attributes"] as Record<string, unknown>)[
                formField.jsonKey
              ]
            : languageData.data[formField.jsonKey];
          if (valueObject === undefined) {
            return null;
          }
          const languageDefaultValue = formField.isAttribute
            ? parseInitialValue(formField.type, valueObject)
            : parseInitialValue(formField.type, valueObject);
          if (languageDefaultValue.length === 0) {
            return null;
          }
          return {
            languageCode: languageData.language,
            refs: languageDefaultValue.map((value) => {
              return {
                ref: React.createRef<HTMLInputElement>(),
                initialValue: value,
              };
            }),
          };
        }),
      ],
    };
  });
};

const formFieldWithRefsFactory = (
  fields: FormField[],
  existingData: Record<string, unknown>,
  supportedLanguages: string[]
): FormFieldWithRefs[] => {
  const nonDefaultLanguages = supportedLanguages.filter((it) => it !== "en");
  // Handles existing data in Editing a form.
  const existingDataPerLanguage = nonDefaultLanguages.mapNotNull((item) => {
    if (existingData[item]) {
      return {
        language: item,
        data: existingData[item] as Record<string, unknown>,
      };
    }
  });
  const moduleFields: FormFieldWithRefs[] = generateFieldRefs(
    fields,
    existingData,
    supportedLanguages,
    existingDataPerLanguage
  );
  // Generating refs if both Start and End time exists? Technically, we can add a single, but can only
  // edit two together. Doesn't show in the form if only one. || operator solves this, but generates ref
  // for both. Populates the existing and creates an empty one.
  if (existingData["startTime"] && existingData["endTime"]) {
    moduleFields.push(
      ...generateFieldRefs(
        SchedulingConfigForm,
        existingData,
        supportedLanguages,
        existingDataPerLanguage
      )
    );
  }
  if (
    existingData?.["attributes"] &&
    (existingData?.["attributes"] as Record<string, unknown>)?.["link"]
  ) {
    moduleFields.push(
      ...generateFieldRefs(
        LinkConfigForm,
        (existingData?.["attributes"] as Record<
          string,
          Record<string, unknown>
        >)?.["link"] ?? {},
        supportedLanguages,
        existingDataPerLanguage
      )
    );
  }
  // if there is links field, it is treated like an array, and an array of generated refs are pushed.
  if (
    existingData?.["attributes"] &&
    (existingData?.["attributes"] as Record<string, unknown>)?.["links"]
  ) {
    const linksArray = (existingData.attributes as Record<string, unknown>)
      .links as Record<string, unknown>[];

    const linksArrayNew = linksArray.map((linkConfig) =>
      generateFieldRefs(
        LinkConfigForm,
        linkConfig ?? {},
        supportedLanguages,
        existingDataPerLanguage
      )
    );

    const finalRefs = groupExistingRefs(lodash.flatten(linksArrayNew));
    finalRefs.forEach((ref) => {
      moduleFields.push(ref);
    });
  }
  return moduleFields;
};

export default formFieldWithRefsFactory;
