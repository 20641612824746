import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import * as Firebase from "firebase/app";
import alphabetize from "alphabetize-object-keys";
import * as lodash from "lodash";
import { stores, tables, TableType } from "../Config";
import { SelectorOption } from "../components/Selector";

interface Table {
  readonly type: TableType;
  readonly key: string;
  readonly document: Record<string, unknown>;
}

interface Store {
  readonly label: string;
  readonly storeKey: string;
  readonly tables: Table[];
}

export interface State {
  readonly storekeys: SelectorOption[];
  readonly selectedStore?: Store;
  readonly selectedTable: string;
  readonly currentObject: string;
}

const initialState: State = {
  storekeys: stores,
  selectedStore: undefined,
  selectedTable: "",
  currentObject: "{}",
};

interface BatchVersionUpdateResult {
  success: boolean;
  data: {
    updates: boolean;
    versionsUpdated: number;
    successful: number;
    failed: number;
    failedIds: string[];
  };
  message: string;
}

class EditorViewModel {
  private readonly firebaseApps: Firebase.app.App[];
  state: State = initialState;
  editorDimensions: { width: number; height: number } = {
    width: window.innerWidth * 0.8,
    height: window.innerHeight,
  };

  get originalObject(): Record<string, unknown> {
    return (
      this.state.selectedStore?.tables?.find(
        (table) => table.key === this.state.selectedTable
      )?.document || {}
    );
  }

  get hasUnsavedChanged(): boolean {
    try {
      return !lodash.isEqual(
        JSON.parse(this.state.currentObject),
        this.originalObject
      );
    } catch (error) {
      return true;
    }
  }

  constructor(firebaseApps: Firebase.app.App[]) {
    makeObservable<EditorViewModel, "copyObjectToSelectedTable">(this, {
      copyObjectToSelectedTable: action.bound,
      state: observable,
      originalObject: computed,
      onCurrentDocumentChanged: action.bound,
      onSelectedTableUpdated: action.bound,
      onSelectedStoreAndTableUpdated: action.bound,
      onSaveClicked: action.bound,
      onDeployClicked: action.bound,
      editorDimensions: observable,
      hasUnsavedChanged: computed,
      onGetRemoteStorekeys: action.bound,
    });
    this.firebaseApps = firebaseApps;
    autorun(async () => {
      await this.onGetRemoteStorekeys();
    });
    window.addEventListener("resize", () => {
      runInAction(() => {
        this.editorDimensions = {
          ...this.editorDimensions,
          width: window.innerWidth * 0.8,
        };
      });
    });
  }

  async onSaveClicked(): Promise<boolean> {
    try {
      const updatedDocument = JSON.parse(this.state.currentObject);
      const defaultFirebaseApp = this.firebaseApps[0];
      await defaultFirebaseApp
        .firestore()
        .collection(this.state.selectedTable)
        .doc(this.state.selectedStore?.storeKey)
        .set(updatedDocument);
      this.copyObjectToSelectedTable(updatedDocument);
      if (
        this.state.selectedStore?.storeKey &&
        (this.state.selectedTable === "stores" ||
          this.state.selectedTable === "themes")
      ) {
        const env = this.firebaseApps.length === 1 ? "prod" : "uat";

        const result = await this.publishToVersion(
          this.state.selectedStore?.storeKey,
          env,
          this.state.selectedTable
        );
        if (result) {
          if (result.data.updates) {
            alert(
              this.getVersionUpdateMessage(this.state.selectedTable, result)
            );
          }
        } else {
          alert(`Unsuccessful updating ${this.state.selectedTable}`);
        }
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async onDeployClicked(): Promise<boolean> {
    try {
      const updatedDocument = JSON.parse(this.state.currentObject);
      const saveDocumentPromises = this.firebaseApps.map((app) => {
        app
          .firestore()
          .collection(this.state.selectedTable)
          .doc(this.state.selectedStore?.storeKey)
          .set(updatedDocument);
      });
      await Promise.all(saveDocumentPromises);
      this.copyObjectToSelectedTable(updatedDocument);
      // Trigger a save on all versions
      if (
        this.state.selectedStore?.storeKey &&
        (this.state.selectedTable === "stores" ||
          this.state.selectedTable === "themes")
      ) {
        const result = await this.publishToVersion(
          this.state.selectedStore?.storeKey,
          "all",
          this.state.selectedTable
        );

        if (result) {
          if (result.data.updates) {
            alert(
              this.getVersionUpdateMessage(this.state.selectedTable, result)
            );
          }
        } else {
          alert(`Unsuccessful updating ${this.state.selectedTable}`);
        }
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private getVersionUpdateMessage(
    databaseTarget: "themes" | "stores",
    result: BatchVersionUpdateResult
  ): string {
    if (!result.success) {
      return `Updating versions unsuccessful`;
    }

    if (!result.data.updates) {
      return "No versions to update.";
    }

    if (result.data.failed > 0) {
      return `Partially updated version ${databaseTarget}.
      Successful updates: ${result.data.successful}
      Failed attempts: ${result.data.failed}
      
      Please check the following version ids:
      ${(result.data.failedIds ?? []).join(", ")}`;
    }

    return `Successfully updated ${result.data.successful} version ${databaseTarget}`;
  }

  // Add counter report
  private async publishToVersion(
    storekey: string,
    env: "prod" | "uat" | "all",
    databaseTarget: "stores" | "themes"
  ): Promise<BatchVersionUpdateResult | undefined> {
    console.log("Publishing:", databaseTarget);
    const options: RequestInit = {
      method: "PUT",
      headers: {
        Authorization: "786d2164-a918-4600-9a36-dd95134c7685",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "X-Venn-Apps-Client": "client-dashboard",
        storekey,
      },
    };
    try {
      if (env === "all") {
        await fetch(
          `https://api.vennapps.com/dashboardUtils/applyManualConfigToAllSchedules?env=uat&targetDatabase=${databaseTarget}`,
          options
        );
        const response = await fetch(
          `https://api.vennapps.com/dashboardUtils/applyManualConfigToAllSchedules?env=prod&targetDatabase=${databaseTarget}`,
          options
        );
        return await response.json();
      }

      const response = await fetch(
        `https://api.vennapps.com/dashboardUtils/applyManualConfigToAllSchedules?env=${env}&targetDatabase=${databaseTarget}`,
        options
      );

      return await response.json();
    } catch (error) {
      alert(`Unsuccessful updating version configs. Error: ${error}`);
    }
  }

  private copyObjectToSelectedTable(updatedDocument: Record<string, unknown>) {
    if (this.state.selectedStore) {
      this.state = {
        ...this.state,
        selectedStore: {
          ...this.state.selectedStore,
          tables: this.state.selectedStore.tables.map((table) => {
            if (table.key === this.state.selectedTable) {
              return {
                ...table,
                document: updatedDocument,
              };
            } else {
              return table;
            }
          }),
        },
      };
    }
  }

  onSelectedTableUpdated(updatedTable: string): void {
    const table = this.state.selectedStore?.tables.find(
      (table) => table.key === updatedTable
    );
    if (table) {
      this.state = {
        ...this.state,
        selectedTable: updatedTable,
        currentObject: JSON.stringify(table.document, null, 2),
      };
    }
  }

  async onSelectedStoreAndTableUpdated(
    storeKey: string,
    tableValue: string
  ): Promise<void> {
    const documentPromises = tables.map((item) => {
      return this.tryFetchDocument(storeKey, item.value);
    });
    const documents = await Promise.all(documentPromises);
    const storeTables: Table[] = documents.map((document, index) => {
      return {
        type: tables[index].label,
        key: tables[index].value,
        document: document ?? {},
      };
    });
    const store: Store = {
      tables: storeTables,
      storeKey: storeKey,
      label: stores.find((item) => item.value === storeKey)?.label ?? "",
    };
    if (document) {
      runInAction(() => {
        this.state = {
          ...this.state,
          selectedStore: store,
        };
      });
    }
    this.onSelectedTableUpdated(tableValue);
  }

  onCurrentDocumentChanged(updatedDocument: string): void {
    this.state = {
      ...this.state,
      currentObject: updatedDocument,
    };
  }

  private async tryFetchDocument(
    storeKey: string,
    collectionName: string
  ): Promise<Record<string, unknown> | null> {
    const defaultApp = this.firebaseApps[0];
    if (storeKey !== "" && collectionName !== "") {
      const document = await defaultApp
        .firestore()
        .collection(collectionName)
        .doc(storeKey)
        .get();
      if (document.exists) {
        return alphabetize(document.data()) ?? null;
      }
    }
    return null;
  }

  async onGetRemoteStorekeys(): Promise<void> {
    const prodApp =
      this.firebaseApps.length === 2
        ? this.firebaseApps[1]
        : this.firebaseApps[0];

    const document = await prodApp
      .firestore()
      .collection("storeEditor")
      .doc("storekeys")
      .get();
    console.log("APPS:", this.firebaseApps);
    console.log("Fetching Storekeys");

    if (document.exists) {
      const data = document.data() as { storekeys: SelectorOption[] };
      runInAction(() => {
        this.state = {
          ...this.state,
          storekeys: [...this.state.storekeys, ...data.storekeys],
        };
      });
    }
  }

  async onAddRemoteStorekey(
    newStorekey: SelectorOption
  ): Promise<{
    success: boolean;
    error?: string | null;
  }> {
    const prodApp =
      this.firebaseApps.length === 2
        ? this.firebaseApps[1]
        : this.firebaseApps[0];

    const ref = prodApp.firestore().collection("storeEditor").doc("storekeys");

    try {
      const document = await ref.get();

      if (!document.exists) {
        return {
          success: false,
          error: "Cannot fetch the document. Please try again",
        };
      }

      const data = document.data() as { storekeys: SelectorOption[] };

      if (data.storekeys === undefined) {
        return {
          success: false,
          error: "Storekey format seems to be incorrect. ",
        };
      }

      await ref.update({ storekeys: [...data.storekeys, newStorekey] });

      runInAction(() => {
        this.state = {
          ...this.state,
          storekeys: [...this.state.storekeys, ...data.storekeys, newStorekey],
        };
      });
    } catch (error) {
      console.error(error);
      return { success: false, error: "Saving storekeys unsuccessful" };
    }

    return { success: true };
  }
}

export default EditorViewModel;
