import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { FormField } from "./FormItems";
import { RefObject } from "react";
import InputProcessor from "./InputProcessor";
import { EditableModule } from "./Modules";
import "../util/ArrayExtensions";

type ResolvedValues = {
  readonly language: string;
  readonly values: (string | number | boolean)[];
};

export type FormItemsWithResolvedValues = {
  readonly formItem: FormField;
  readonly values: ResolvedValues[];
};

export type UnresolvedFormItems = {
  error: Error;
  formField: FormField;
};

export type FormFieldWithRefsResolverResult = {
  success: FormItemsWithResolvedValues[];
  errors: UnresolvedFormItems[];
};

export const formFieldWithRefsResolver = async (
  module: EditableModule,
  supportedLanguages: string[],
  refs: FormFieldWithRefs[],
  inputProcessor: InputProcessor
): Promise<FormFieldWithRefsResolverResult> => {
  const resolvedFormItems: FormItemsWithResolvedValues[] = [];
  const unResolvedFormItems: UnresolvedFormItems[] = [];
  for (const formItemValues of refs) {
    const formItem = formItemValues.formItem;
    const languageValues = formItemValues.refs;
    const resolvedLanguagesValues: ResolvedValues[] = [];
    try {
      for (const languageValue of languageValues) {
        const language = languageValue.languageCode;
        const resolvedValues = await Promise.all(
          languageValue.refs.mapNotNull((ref) => {
            const instance = (ref.ref as RefObject<HTMLInputElement>).current;
            if (instance) {
              if (
                instance.value === "" &&
                ref.initialValue &&
                formItem.type === "file"
              ) {
                return ref.initialValue;
              }
              if (instance.value === "" && !formItem.required) {
                return null;
              }
              return inputProcessor.process(formItemValues.formItem, instance);
            }
            return null;
          })
        );
        resolvedLanguagesValues.push({
          language: language,
          values: resolvedValues,
        });
      }
      resolvedFormItems.push({
        formItem: formItem,
        values: resolvedLanguagesValues,
      });
    } catch (e) {
      unResolvedFormItems.push({
        error: e as Error,
        formField: formItem,
      });
    }
  }
  for (const computedField of module.fields.filter(
    (item) => item.computed !== undefined
  )) {
    const requiredFields =
      computedField.computed?.computedFrom?.mapNotNull((fieldJsonKey) =>
        resolvedFormItems.find((item) => item.formItem.jsonKey === fieldJsonKey)
      ) ?? [];
    if (
      requiredFields.length !=
      (computedField.computed?.computedFrom?.length ?? 0)
    ) {
      unResolvedFormItems.push({
        formField: computedField,
        error: Error(`Unable to compute ${computedField.label}`),
      });
    } else {
      const languageValues: ResolvedValues[] = [];
      try {
        for (const language of supportedLanguages) {
          const valuesForLanguage = requiredFields.mapNotNull(
            (field) =>
              field.values.find((value) => value.language === language)
                ?.values ??
              field.values.find((value) => value.language === "en")?.values
          );
          const computedValue = await computedField.computed?.computeFunction(
            valuesForLanguage
          );
          languageValues.push({
            language: language,
            values: computedValue ? [computedValue] : [],
          });
        }
        resolvedFormItems.push({
          formItem: computedField,
          values: languageValues,
        });
      } catch (e) {
        unResolvedFormItems.push({
          formField: computedField,
          error: e as Error,
        });
      }
    }
  }
  return {
    success: resolvedFormItems,
    errors: unResolvedFormItems,
  };
};
