import React from "react";
import styles from "../../styles/SearchSideView.module.css";
import Button from "../../components/Button";
import AppBuilderViewModel from "./AppBuilderViewModel";
import { observer } from "mobx-react";
import { Clipboard } from "react-feather";
import uploaderStyles from "./styles/Fileuploader.module.css";

interface Props {
  viewModel: AppBuilderViewModel;
}

const AppBuilder: React.FC<Props> = ({ viewModel }) => {
  return (
    <div className={styles.searchSideView}>
      {viewModel.state.result !== "" && (
        <div className={uploaderStyles.resultContainer}>
          <div className={uploaderStyles.resultText}>
            {viewModel.state.result}
          </div>
          <button
            className={uploaderStyles.clipboardButton}
            onClick={() =>
              navigator.clipboard.writeText(viewModel.state.result)
            }
          >
            <Clipboard />
          </button>
        </div>
      )}

      {viewModel.state.hasError && <p>{viewModel.state.result}</p>}
      <Button
        isDisabled={viewModel.state.submitting}
        label={`Build Android Flavor`}
        onClick={async () => {
          await viewModel.onSubmitClicked();
        }}
      />
    </div>
  );
};

export default observer(AppBuilder);
