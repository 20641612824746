import React from "react";
import Select from "react-select";

export interface SelectorOption {
  value: string;
  label: string;
}
interface SelectorProps {
  readonly isDisabled?: boolean;
  readonly currentValue: string;
  readonly placeholder: string;
  readonly options: SelectorOption[];
  readonly onValueChanged: (newValue: string) => void;
  readonly id?: string;
}

const Selector: React.FunctionComponent<SelectorProps> = ({ ...props }) => (
  <Select
    id={props.id}
    placeholder={props.placeholder}
    isDisabled={props.isDisabled ?? false}
    isSearchable={true}
    value={props.options.find((item) => item.value === props.currentValue)}
    isOptionSelected={(item) => item.value === props.currentValue}
    options={props.options}
    onChange={(item) => props.onValueChanged(item?.value ?? "")}
  />
);

export default Selector;
