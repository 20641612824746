import * as lodash from "lodash";
import { LinkConfigForm } from "./Modules";
import { FormItemsWithResolvedValues } from "./FormFieldWithRefsResolver";

export const formFieldValuesParser = (
  supportedLanguages: string[],
  resolvedFormItems: FormItemsWithResolvedValues[]
): Record<string, unknown> => {
  const jsonData = supportedLanguages.map((languageCode) => {
    const languageData = resolvedFormItems.mapNotNull((item) => {
      const values = item.values.filter((it) => it.language === languageCode);
      if (values.length > 0) {
        return {
          formItem: item.formItem,
          values: item.values.filter((it) => it.language === languageCode),
        };
      }
    });
    const root: Record<string, unknown> = {};
    const attributes: Record<string, unknown> = {};
    const linkConfig: Record<string, unknown>[] = [];

    const currentLinkConfigFields = languageData.filter(
      (item) =>
        LinkConfigForm.find(
          (linkConfigItem) => linkConfigItem.jsonKey === item.formItem.jsonKey
        ) !== undefined
    );

    currentLinkConfigFields.forEach((field) => {
      field?.values.forEach((inputValue, index) => {
        if (linkConfig.length === index) {
          linkConfig.push({
            [field.formItem.jsonKey]: inputValue.values[0],
          });
        } else {
          linkConfig[index] = {
            ...linkConfig[index],
            [field.formItem.jsonKey]: inputValue.values[0],
          };
        }
      });
    });

    languageData
      .filter(
        (item) =>
          LinkConfigForm.find(
            (linkConfigItem) => linkConfigItem.jsonKey === item.formItem.jsonKey
          ) === undefined
      )
      .forEach((formInput) => {
        // imageUrls
        const key = formInput.formItem.jsonKey;

        const values = lodash.flatten(
          formInput.values.map((value) => value.values)
        );

        const value = values.length === 1 ? values[0] : values;
        if (formInput.formItem.isAttribute) {
          if (typeof value === "string" && value.startsWith("#")) {
            attributes[key] = {
              hex: value,
            };
          } else {
            attributes[key] = value;
          }
        } else {
          root[key] = value;
        }
      });

    if (linkConfig.length === 1) {
      attributes["link"] = linkConfig[0];
    } else if (linkConfig.length > 1) {
      attributes["links"] = linkConfig;
    }
    root["attributes"] = attributes;
    return {
      languageCode: languageCode,
      rootData: root,
    };
  });

  const defaultJsonData =
    jsonData.find((item) => item.languageCode === "en")?.rootData ?? {};

  supportedLanguages
    .filter((item) => item !== "en")
    .forEach((languageCode) => {
      const langData =
        jsonData.find((item) => item.languageCode === languageCode)?.rootData ??
        {};
      if (Object.keys(langData).length > 0) {
        // if (langData !== {}) {
        defaultJsonData[languageCode] = langData;
      }
    });
  return defaultJsonData;
};
