import React from "react";
import { Form } from "react-bootstrap";
import FormItemRenderer from "./FormItemRenderer";
import { FormFieldWithRefs } from "./FormFieldWithRefs";
import { FormField } from "./FormItems";
import { useObserver } from "mobx-react";
import { FormFieldWithError } from "./AddModuleState";

interface FormRendererProps {
  readonly isSubmitting: boolean;
  readonly formFieldRefs: FormFieldWithRefs[];
  readonly onAddRef: (field: FormField, languageCode: string) => void;
  readonly languages: string[];
  readonly errors: FormFieldWithError[];
}

const FormRenderer: React.FunctionComponent<FormRendererProps> = ({
  isSubmitting,
  formFieldRefs,
  onAddRef,
  languages,
  errors,
}) => {
  return useObserver(() => (
    <Form>
      <div style={{ marginTop: "20px" }} />
      <Form.Group>
        {formFieldRefs.map((item, index) => (
          <FormItemRenderer
            isSubmitting={isSubmitting}
            languages={languages}
            error={
              errors.find(
                (errorItem) =>
                  errorItem.formField.jsonKey === item.formItem.jsonKey
              )?.error
            }
            onAddRef={(languageCode) => onAddRef(item.formItem, languageCode)}
            formField={item}
            key={`${item.formItem.label}${index}`}
          />
        ))}
      </Form.Group>
    </Form>
  ));
};

export default FormRenderer;
