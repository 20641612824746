import React from "react";
import { ModuleType } from "../AddModule/Modules";
import ImageModulePreview from "./ImageModulePreview";
import ImageStackModulePreview from "./ImageStackModulePreview";
import SpacerModulePreview from "./SpacerModulePreview";
import ImageCarouselPreview from "./ImageCarouselPreview";

interface ModulePreviewProps {
  readonly moduleType: ModuleType;
  readonly data: Record<string, unknown>;
}

const ModulePreview: React.FunctionComponent<ModulePreviewProps> = ({
  ...props
}) => {
  if (props.moduleType === "VImageWithPadding") {
    return <ImageModulePreview data={props.data} />;
  }

  if (props.moduleType === "VSpacer") {
    return <SpacerModulePreview data={props.data} />;
  }

  if (props.moduleType === "VStackImage") {
    return <ImageStackModulePreview data={props.data} />;
  }

  if (props.moduleType === "VImageCarousel") {
    return <ImageCarouselPreview data={props.data} />;
  }
  return <></>;
};

export default ModulePreview;
