import React from "react";
import styles from "../styles/login.module.css";
import { ServiceLoaderContext } from "../index";

interface LoginProps {
  readonly onLoginComplete: () => void;
}

const Login: React.FunctionComponent<LoginProps> = ({ ...props }) => {
  return (
    <ServiceLoaderContext.Consumer>
      {(serviceLoader) => (
        <div className={styles.container}>
          <button
            className={styles.loginButton}
            onClick={async () => {
              const result = await serviceLoader.loginViewModel.onLoginClicked();
              if (result) {
                props.onLoginComplete();
              }
            }}
          >
            Sign in
          </button>
        </div>
      )}
    </ServiceLoaderContext.Consumer>
  );
};

export default Login;
