import { action, makeObservable, observable, runInAction } from "mobx";
import * as firebase from "firebase/app";

type DataType = {
  [key: string]: {
    connections: {
      [key: string]: {
        currentStore: string;
        currentTable: string;
        userEmail: string;
      };
    };
  };
};

export interface ActiveUser {
  readonly email: string;
  readonly currentStore: string;
  readonly currentPage: string;
}

class ActiveUsersViewModel {
  private readonly firebaseApp: firebase.app.App;
  state: ActiveUser[] = [];
  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    makeObservable(this, {
      state: observable,
      observerActiveUser: action.bound,
    });
  }

  observerActiveUser(): void {
    this.firebaseApp
      .database()
      .ref("storeEditor/users/")
      .on("value", (snapshot) => {
        const data = snapshot.toJSON() as DataType;
        runInAction(() => {
          this.state = Object.keys(data)
            .map((key) => {
              const connections = data[key].connections;
              return Object.keys(connections).map((key) => {
                const userData = connections[key];
                return {
                  email: userData.userEmail,
                  currentStore: userData.currentStore,
                  currentPage: userData.currentTable,
                };
              });
            })
            .flat();
        });
      });
  }
}

export default ActiveUsersViewModel;
