import { useObserver } from "mobx-react";
import React, { useState } from "react";
import Button from "../../components/Button";
import Selector from "../../components/Selector";
import EditorViewModel from "../../Editor/EditorViewModel";
// import Selector from "../../components/Selector";
import styles from "./SyncSideView.module.css";
import SyncSideViewModel from "./SyncSideViewModel";

interface SyncSideViewProps {
  viewModel: SyncSideViewModel;
  editorViewModel: EditorViewModel;
}

const prodSyncUrl = "https://venn-prod-70222.appspot.com";
const uatSyncUrl = "https://venn-uat.appspot.com";

const SyncSideView: React.FunctionComponent<SyncSideViewProps> = ({
  viewModel,
  editorViewModel,
}) => {
  const [] = useState();
  return useObserver(() => (
    <div className={styles.SyncSideView}>
      <div className={styles.SyncSideViewSelectContainer}>
        <div>
          <label htmlFor="syncType">Sync Type</label>
          <Selector
            id="syncType"
            currentValue={""}
            options={viewModel.syncOptions}
            placeholder="Select Sync"
            onValueChanged={viewModel.onSyncTypeSelected}
          />
        </div>
      </div>
      <div className={styles.SyncSideViewButtonContainer}>
        <Button
          isDisabled={viewModel.isDisabled}
          label="Sync UAT"
          onClick={() =>
            viewModel.onSyncClicked(
              uatSyncUrl,
              editorViewModel.state.selectedStore?.storeKey
            )
          }
        ></Button>
        <Button
          isDisabled={viewModel.isDisabled}
          label="Sync Production"
          onClick={() => {
            viewModel.onSyncClicked(
              prodSyncUrl,
              editorViewModel.state.selectedStore?.storeKey
            );
          }}
        ></Button>
      </div>
    </div>
  ));
};

export default SyncSideView;
