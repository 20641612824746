import * as firebase from "firebase/app";
import { isValidUser } from "../util/UserValidation";

class LoginViewModel {
  constructor(private readonly firebaseApps: firebase.app.App[]) {}

  async onLoginClicked(): Promise<boolean> {
    const creds: firebase.auth.UserCredential[] = [];
    for (const app of this.firebaseApps) {
      creds.push(
        await app.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
      );
    }
    try {
      const isValidLogin: boolean = creds
        .map((creds) => isValidUser(creds.user))
        .reduce((a, b) => a && b, true);
      if (!isValidLogin) {
        await Promise.all(this.firebaseApps.map((app) => app.auth().signOut()));
      }
      return isValidLogin;
    } catch (error) {
      return false;
    }
  }
}

export default LoginViewModel;
