import React from "react";

interface ImageStackModulePreviewProps {
  readonly data: Record<string, unknown>;
}

interface Config {
  readonly attributes: {
    readonly backgroundColor: string;
    readonly imageHeight: number;
    readonly imageSpacing: number;
    readonly imageWidth: number;
    readonly images: string[];
  };
}

const ImageStackModulePreview: React.FC<ImageStackModulePreviewProps> = (
  props
) => {
  const config: Config = (props.data as unknown) as Config;
  const images = config.attributes.images;

  const SingleImage = ({ image }: { image: string }) => (
    <img
      src={image}
      alt="VStackImage"
      style={{
        margin: "auto",
        objectFit: "cover",
        maxHeight: `200px`,
        width: "auto",
        marginBottom: "20px",
        backgroundColor: config.attributes.backgroundColor,
      }}
    ></img>
  );

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {typeof images === "object" ? (
        images.map((image, index) => (
          <SingleImage image={image} key={`${image}${index}`} />
        ))
      ) : (
        <SingleImage image={images} />
      )}
    </div>
  );
};
export default ImageStackModulePreview;
