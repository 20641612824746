import React from "react";
import { Carousel } from "react-bootstrap";
import "./styles/carouselCSSOverride.css";

interface ImageCarouselPreviewProps {
  readonly data: Record<string, unknown>;
}

interface Config {
  readonly attributes: {
    readonly images: string[];
    readonly animated: string;
    readonly scrollStyle: string;
    readonly imagebackGroundColor: string;
    readonly padding: string;
  };
  readonly heightMultiplier: number;
}

const ImageCarouselPreview: React.FC<ImageCarouselPreviewProps> = (props) => {
  const config: Config = (props.data as unknown) as Config;
  const images = config.attributes.images;

  return (
    <Carousel style={{ overflow: "hidden" }}>
      {images.map((image, index) => (
        <Carousel.Item key={`${image}${index}`}>
          <div style={{ width: "100%", display: "flex" }}>
            <img
              style={{
                margin: "1rem auto",
                width: "auto",
                maxHeight: "500px",
                padding: config.attributes.padding,
                backgroundColor: config.attributes.imagebackGroundColor,
              }}
              src={image}
              alt="VImageCarousel"
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarouselPreview;
