import * as Firebase from "firebase/app";
import { action, makeObservable, observable, runInAction } from "mobx";
import { isValidUser } from "../util/UserValidation";
import { History } from "history";

class AppViewModel {
  isLoading = true;

  constructor(
    private readonly firebaseApps: Firebase.app.App[],
    private readonly history: History
  ) {
    makeObservable<AppViewModel, "checkUser">(this, {
      isLoading: observable,
      checkUser: action.bound,
      onLoginComplete: action.bound,
    });
  }

  async checkUser(): Promise<void> {
    const promises = this.firebaseApps.map(
      (app) =>
        new Promise<Firebase.User | null>((resolve, reject) => {
          const unsubscribe = app.auth().onAuthStateChanged(
            (user) => {
              resolve(user);
              unsubscribe();
            },
            (error) => {
              reject(error);
              unsubscribe();
            }
          );
        })
    );
    const users = await Promise.all(promises);
    const isLoggedIn =
      users.map((user) => isValidUser(user)).reduce((a, b) => a && b, true) &&
      users.length > 0;
    const user = users[0];
    runInAction(() => {
      this.isLoading = false;
    });
    if (isLoggedIn && user) {
      if (this.history.location.pathname === "login") {
        this.history.push("/");
      }
    } else {
      this.history.push("/login");
    }
  }

  onLoginComplete(): void {
    const currentUser =
      this.firebaseApps.length > 0
        ? this.firebaseApps[0].auth().currentUser
        : null;
    runInAction(() => {
      this.isLoading = false;
    });
    if (currentUser) {
      this.history.push("/");
    }
  }
}

export default AppViewModel;
