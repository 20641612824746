import AppViewModel from "./App/AppViewModel";
import * as Firebase from "firebase/app";
import ActiveUsersViewModel from "./ActiveUsers/ActiveUsersViewModel";
import EditorViewModel from "./Editor/EditorViewModel";
import UserObserverViewModel from "./UserObserver/UserObserverViewModel";
import LoginViewModel from "./Login/LoginViewModel";
import SearchSideViewViewModel, {
  SearchOptions,
} from "./SearchContainer/SearchSideView/SearchSideViewViewModel";
import AddModuleViewModel from "./AddModule/AddModuleViewModel";
import InputProcessorImpl from "./AddModule/InputProcessorImpl";
import ImageInputProcessor from "./AddModule/ImageInputProcessor";
import HomepageViewerViewModel from "./TableViewer/HomepageViewer/HomepageViewerViewModel";
import { HomepageModule } from "./TableViewer/HomepageViewer/types";
import { History } from "history";
import FileUploaderViewModel from "./SearchContainer/FileUploader/FileUploaderViewModel";
import SyncSideViewModel from "./SearchContainer/SyncSide/SyncSideViewModel";
import { syncOptions } from "./SearchContainer/SyncSide/syncOptions";
import AppBuilderViewModel from "./SearchContainer/AppBuilder/AppBuilderViewModel";

class ServiceLoader {
  constructor(
    private readonly firebaseApps: Firebase.app.App[],
    private readonly history: History
  ) {}

  private get apiBaseUrl(): string {
    if (process.env.NODE_ENV === "production") {
      return "https://api.vennapps.com/";
    } else {
      return "https://api-uat.vennapps.com/";
    }
  }

  get fileUploaderViewModel(): FileUploaderViewModel {
    return new FileUploaderViewModel(this.imageInputProcessor());
  }

  get appBuilderViewModel(): AppBuilderViewModel {
    return new AppBuilderViewModel(
      this.firebaseApps[0],
      this.editorViewModel.state.selectedStore?.storeKey ?? "",
      this.apiBaseUrl
    );
  }

  private _appViewModel: AppViewModel | undefined;
  get appViewModel(): AppViewModel {
    return (
      this._appViewModel ??
      (this._appViewModel = new AppViewModel(this.firebaseApps, this.history))
    );
  }

  private _activeUsersViewModel: ActiveUsersViewModel | undefined;
  get activeUsersViewModel(): ActiveUsersViewModel {
    if (this._activeUsersViewModel === undefined) {
      this._activeUsersViewModel = new ActiveUsersViewModel(
        this.firebaseApps[0]
      );
      this._activeUsersViewModel.observerActiveUser();
    }
    return this._activeUsersViewModel;
  }

  private _editorViewModel: EditorViewModel | undefined;
  get editorViewModel(): EditorViewModel {
    if (this._editorViewModel === undefined) {
      this._editorViewModel = new EditorViewModel(this.firebaseApps);
    }
    return this._editorViewModel;
  }

  private _loginViewModel: LoginViewModel | undefined;
  get loginViewModel(): LoginViewModel {
    if (this._loginViewModel === undefined) {
      this._loginViewModel = new LoginViewModel(this.firebaseApps);
    }
    return this._loginViewModel;
  }

  private _homepageViewModel: HomepageViewerViewModel | undefined;
  get homepageViewModel(): HomepageViewerViewModel {
    if (this._homepageViewModel === undefined) {
      this._homepageViewModel = new HomepageViewerViewModel(
        this.editorViewModel,
        this.history
      );
    }
    return this._homepageViewModel;
  }

  searchSideViewModel(option: SearchOptions): SearchSideViewViewModel {
    return new SearchSideViewViewModel(
      this.firebaseApps[0],
      option,
      this.editorViewModel.state.selectedStore?.storeKey ?? ""
    );
  }

  syncSideViewModel(): SyncSideViewModel {
    return new SyncSideViewModel(syncOptions);
  }

  imageInputProcessor(): ImageInputProcessor {
    return new ImageInputProcessor(
      this.apiBaseUrl,
      this.editorViewModel.state.selectedStore?.storeKey ?? ""
    );
  }

  addModuleViewModel(
    homepageModule?: HomepageModule,
    onSaveListener?: (module: Record<string, unknown>) => void
  ): AddModuleViewModel {
    /* eslint-disable */
    const viewModel = new AddModuleViewModel(
      this.editorViewModel,
      new InputProcessorImpl([
        {
          type: "file",
          processor: this.imageInputProcessor(),
        },
      ]),
      onSaveListener ?? this.homepageViewModel.onNewModuleCreated
    );
    if (homepageModule) {
      const isInstagram =
        homepageModule.attributes.instagramService !== undefined;
      viewModel.onModuleSelected(
        (allModules) =>
          allModules.find((item) =>
            isInstagram
              ? item.readableModuleName === "Image Grid (Instagram)"
              : item.type === homepageModule.moduleType
          )!,
        homepageModule
      );
    }
    return viewModel;
  }

  private _userObserverViewModel: UserObserverViewModel | undefined;
  get userObserverViewModel(): UserObserverViewModel {
    if (this._userObserverViewModel === undefined) {
      this._userObserverViewModel = new UserObserverViewModel(
        this.firebaseApps[0],
        this.editorViewModel
      );
      this._userObserverViewModel.startObserving().then();
    }
    return this._userObserverViewModel;
  }
}

export default ServiceLoader;
