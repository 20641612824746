import { FormField } from "./FormItems";
import { isColor, isPositiveNumber } from "./Validation";

export type ModuleType =
  | "VImageWithPadding"
  | "VSpacer"
  | "VImageCarousel"
  | "VCustomImageGrid"
  | "VStackImage";

export type EditableModule = {
  type: ModuleType;
  readableModuleName: string;
  fields: FormField[];
  linkConfig: FormField[];
  scheduling: FormField[];
};

export const LinkConfigForm: FormField[] = [
  {
    jsonKey: "payload",
    multipleInput: false,
    description: "Link to be used when the module is clicked",
    type: "text",
    required: true,
    label: "Payload",
    isAttribute: false,
  },
  {
    jsonKey: "type",
    multipleInput: false,
    description: "Link type",
    type: "text",
    required: true,
    label: "Link type",
    isAttribute: false,
  },
  {
    jsonKey: "fallbackWebUrl",
    multipleInput: false,
    description: "Fallback web URL",
    type: "text",
    required: false,
    label: "Fallback Web URL",
    isAttribute: false,
  },
  {
    jsonKey: "payloadAndroid",
    multipleInput: false,
    description: "Android payload",
    type: "text",
    required: false,
    label: "Android Payload",
    isAttribute: false,
  },
];

export const SchedulingConfigForm: FormField[] = [
  {
    jsonKey: "startTime",
    multipleInput: false,
    description: "Date and time when this module will be displayed",
    type: "datetime-local",
    required: false,
    label: "Start time",
    isAttribute: false,
  },
  {
    jsonKey: "endTime",
    multipleInput: false,
    description: "Date and time when this module will be hidden",
    type: "datetime-local",
    required: false,
    label: "End time",
    isAttribute: false,
  },
];

export const VImageWithPaddingForm: EditableModule = {
  type: "VImageWithPadding",
  readableModuleName: "Image with padding",
  fields: [
    {
      jsonKey: "imageUrl",
      multipleInput: false,
      description: "Image to be displayed",
      label: "Image",
      required: true,
      type: "file",
      isAttribute: true,
    },
    {
      jsonKey: "heightMultiplier",
      multipleInput: false,
      description:
        "Height multiplier to control the height of the images when its displayed",
      label: "Height Multiplier",
      required: true,
      type: "number",
      isAttribute: false,
      computed: {
        computedFrom: ["imageUrl"],
        computeFunction: async (
          values: (string | number | boolean)[][]
        ): Promise<string | number | boolean> => {
          return new Promise((resolve) => {
            const imgElement = document.createElement("img");
            imgElement.onload = () => {
              resolve(imgElement.height / imgElement.width);
            };
            imgElement.src = values[0][0] as string;
          });
        },
      },
    },
    {
      jsonKey: "backgroundColor",
      multipleInput: false,
      description: "Background to be displayed behind the image",
      label: "Background Color",
      required: true,
      type: "color",
      validation: (value: string | number | boolean) =>
        typeof value === "string" && isColor(value),
      isAttribute: true,
    },
    {
      jsonKey: "padding",
      multipleInput: false,
      description: "Padding to be applied to the image",
      label: "Padding",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

const VSpacerForm: EditableModule = {
  type: "VSpacer",
  readableModuleName: "Spacer",
  fields: [
    {
      jsonKey: "spacerBackgroundColor",
      multipleInput: false,
      description: "Color of the space created by this module",
      label: "Color",
      required: true,
      type: "color",
      isAttribute: true,
      validation: (value: string | number | boolean) =>
        typeof value === "string" && isColor(value),
    },
    {
      type: "number",
      jsonKey: "height",
      multipleInput: false,
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      description: "Height of the space in pixels",
      label: "Height",
      required: true,
      isAttribute: false,
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

const VImageCarouselForm: EditableModule = {
  type: "VImageCarousel",
  readableModuleName: "Image Carousel",
  fields: [
    {
      jsonKey: "images",
      type: "file",
      multipleInput: true,
      isAttribute: true,
      required: true,
      label: "Images",
      description: "Images to be displayed in carousel",
    },
    {
      jsonKey: "animated",
      type: "text",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Animated",
      description: "Enable automatic image switching animation",
    },
    {
      jsonKey: "scrollStyle",
      type: "text",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Scroll Style",
      description: `Only supports "paging"`,
    },
    {
      jsonKey: "imageBackgroundColor",
      type: "color",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Background Color",
      description: "Background color displayed behind images",
    },
    {
      jsonKey: "padding",
      multipleInput: false,
      description: "Padding to be applied to images",
      label: "Padding",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
    {
      jsonKey: "heightMultiplier",
      multipleInput: false,
      description:
        "Height multiplier to control the height of the images when its displayed",
      label: "Height Multiplier",
      required: true,
      type: "number",
      isAttribute: false,
      computed: {
        computedFrom: ["images"],
        computeFunction: async (
          values: (string | number | boolean)[][]
        ): Promise<string | number | boolean> => {
          return new Promise((resolve) => {
            const imgElement = document.createElement("img");
            imgElement.onload = () => {
              resolve(imgElement.height / imgElement.width);
            };
            imgElement.src = values[0][0] as string;
          });
        },
      },
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

const VCustomImageGrid: EditableModule = {
  type: "VCustomImageGrid",
  readableModuleName: "Image Grid",
  fields: [
    {
      jsonKey: "imageUrls",
      type: "file",
      multipleInput: true,
      isAttribute: true,
      required: false,
      label: "Images",
      description: "Images to be displayed in grid",
    },
    {
      jsonKey: "cellHeightMultiplier",
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Individual item height multiplier",
      description: "Height multiplier for each item in the grid",
    },
    {
      jsonKey: "backgroundColor",
      type: "color",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Background colour",
      description: `Background colour of the module`,
    },
    {
      jsonKey: "padding",
      type: "number",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Padding",
      description: "Padding between images in grid",
    },
    {
      jsonKey: "xImages",
      multipleInput: false,
      description: "Number of images to be displayed on the X axis.",
      label: "Number of X items",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
    {
      jsonKey: "yImages",
      multipleInput: false,
      description: "Number of images to be displayed on the Y axis.",
      label: "Number of Y items",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

const VCustomImageGridInstagram: EditableModule = {
  type: "VCustomImageGrid",
  readableModuleName: "Image Grid (Instagram)",
  fields: [
    {
      jsonKey: "cellHeightMultiplier",
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Individual item height multiplier",
      description: "Height multiplier for each item in the grid",
    },
    {
      jsonKey: "backgroundColor",
      type: "color",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Background colour",
      description: `Background colour of the module`,
    },
    {
      jsonKey: "padding",
      type: "number",
      multipleInput: false,
      isAttribute: true,
      required: true,
      label: "Padding",
      description: "Padding between images in grid",
    },
    {
      jsonKey: "xImages",
      multipleInput: false,
      description: "Number of images to be displayed on the X axis.",
      label: "Number of X items",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
    {
      jsonKey: "yImages",
      multipleInput: false,
      description: "Number of images to be displayed on the Y axis.",
      label: "Number of Y items",
      required: true,
      type: "number",
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      isAttribute: true,
    },
    {
      jsonKey: "instagramService",
      type: "select",
      options: ["foursixty", "snapppt", "candid", "covet"],
      multipleInput: false,
      isAttribute: true,
      required: false,
      label: "Instagram service",
      description: "Instragram service to populate grid content.",
    },
    {
      jsonKey: "instagramServiceUsername",
      type: "text",
      multipleInput: false,
      isAttribute: true,
      required: false,
      label: "Instagram service username",
      description:
        "Username or identifier of the instagram service used to populate grid content.",
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

const VStackImage: EditableModule = {
  type: "VStackImage",
  readableModuleName: "Image Stack",
  fields: [
    {
      jsonKey: "images",
      type: "file",
      multipleInput: true,
      isAttribute: true,
      required: true,
      label: "Images",
      description: "Images to be displayed in the Stack",
    },
    {
      jsonKey: "backgroundColor",
      multipleInput: false,
      description: "Background to be displayed behind the image",
      label: "Background Color",
      required: true,
      type: "color",
      validation: (value: string | number | boolean) =>
        typeof value === "string" && isColor(value),
      isAttribute: true,
    },
    {
      type: "number",
      jsonKey: "imageHeight",
      multipleInput: false,
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      description: "Height of the Images in pixels",
      label: "Image Height",
      required: true,
      isAttribute: true,
    },
    {
      type: "number",
      jsonKey: "imageWidth",
      multipleInput: false,
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      description: "Width of image in pixels",
      label: "Image Width",
      required: true,
      isAttribute: true,
    },
    {
      type: "number",
      jsonKey: "imageSpacing",
      multipleInput: false,
      validation: (value: string | number | boolean) =>
        typeof value === "number" && isPositiveNumber(value),
      description: "Height of the space in pixels",
      label: "Image Spacing",
      required: true,
      isAttribute: true,
    },
  ],
  linkConfig: LinkConfigForm,
  scheduling: SchedulingConfigForm,
};

export const AvailableModules: EditableModule[] = [
  VImageWithPaddingForm,
  VSpacerForm,
  VImageCarouselForm,
  VCustomImageGrid,
  VCustomImageGridInstagram,
  VStackImage,
];
